import React, { useEffect, useState } from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import {
  StatusZoneKeys,
  TypeBreadcrumb,
  TypeCost,
  TypeTooltip
} from "../../constants/types";
import Comments from "../../components/comments/Comments";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { useStore } from "effector-react";
import {
  avis,
  comments,
  isTokenValidStore,
  regions,
  userStore,
  zoneStore
} from "../../state/store";
import {
  deleteZone,
  getAvisFromZone,
  getCommentsFromZone,
  getIsTokenValid,
  getZoneDetail,
  reopenZone,
  setIsTokenValid
} from "../../state/effect";
import { exportData, getQueryVariable } from "../../utils/utils";
import { COSTS, ROLES, STATUT_ZONE, UNITS } from "../../constants/constant";
import parse from "html-react-parser";
import { DATE_LITTERAL_FORMAT } from "../../constants/dateFormat";
import moment from "moment";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import {
  faBookOpen,
  faFileArchive,
  faList,
  faMailBulk,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { Location, navigate } from "@reach/router";
import CardLayout from "../../components/layouts/CardLayout";
import UnorderedList from "../../components/liste/UnorderedList";
import { Col, Row } from "reactstrap";
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import api from "../../network/api";
import DuplicateZoneModal from "../../components/modal/DuplicateZoneModal";
import { PATH_EDIT, PATH_STATS, ROUTE_HOME } from "../../constants/route";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

type Props = {
  intl: InjectedIntl;
  path: string;
  zoneId?: number;
};

function ZoneDetail({ intl, zoneId }: Props) {
  const { formatMessage } = intl;
  // States
  const [breadcrumb] = useState([
    {
      id: "fil-ariane-detail",
      label: formatMessage({ id: "breadcrumb_details_zone_label" })
    }
  ] as TypeBreadcrumb[]);
  const [loading, setLoading] = useState(false as boolean);
  const isConnected = sessionStorage && sessionStorage.token;

  // Stores
  const commentsStore = useStore(comments);
  const avisStore = useStore(avis);
  const regionList = useStore(regions);
  const zone = useStore(zoneStore);
  const isTokenValid = useStore(isTokenValidStore);
  const user = useStore(userStore);
  // Effects
  useEffect(() => {
    fetchCommentsAndAvis();
    fetchZoneDetail();
  }, [zoneId]);

  async function fetchCommentsAndAvis() {
    if (zoneId) {
      await getCommentsFromZone(zoneId);
      await getAvisFromZone(zoneId);
    }
  }

  async function fetchZoneDetail() {
    if (zoneId) {
      await getZoneDetail(zoneId);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = getQueryVariable("token");
    if (token) {
      getIsTokenValid(zoneId + "/" + token);
    }
    api.postJSON(
      "/api/zones/" + zoneId + "/stats/increment",
      JSON.stringify(null)
    );
    return () => {
      setIsTokenValid(false);
    };
  }, []);

  const depositPotentialArray =
    zone?.pcDepositPotentialResources &&
    zone.pcDepositPotentialResources.map(potential =>
      parseInt(potential.value ? potential.value : "0")
    );
  const totalDepositPotentials =
    depositPotentialArray &&
    depositPotentialArray.reduce(
      (potential, potential2) => potential + potential2,
      0
    );
  const region = regionList.filter(
    region => zone && region.id.toString() === zone.pcRegionId
  )[0];
  const listItems =
    zone && region
      ? [
          {
            title: "detail_zone_deposit_potential_label",
            content: zone.pcDepositPotentialResources
              ? `<p>${zone.pcDepositPotentialResources
                  .map(depositPotential =>
                    parse(
                      `${depositPotential.label}: ${depositPotential.value} ${UNITS.GWH}`
                    )
                  )
                  .join("<br/>")}</p>`
              : "",
            total: (
              <div>
                {totalDepositPotentials && totalDepositPotentials !== 0 ? (
                  <div>
                    <FormattedMessage id={"total_deposit_potential"} /> :{" "}
                    {totalDepositPotentials.toString()} {UNITS.GWH}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )
          },
          {
            title: "detail_zone_iv_value_label",
            content:
              zone.ivValue || zone.ivValue === 0 ? zone.ivValue.toString() : "",
            unit: UNITS.EURO_BY_NM3H
          },
          {
            title: "detail_zone_medium_cost_label",
            content: zone.mediumCost ? COSTS[zone.mediumCost as TypeCost] : "",
            tooltip: {
              placement: "top",
              afterText: " : ",
              tooltipTitle: formatMessage({
                id: "tooltip_zone_medium_cost_title"
              }),
              tooltipContent: (
                <div>
                  <div>
                    <FormattedMessage id={"tooltip_zone_medium_cost_text_1"} />
                  </div>
                  <div>
                    <FormattedMessage id={"tooltip_zone_medium_cost_text_2"} />
                  </div>
                  <div>
                    <FormattedMessage id={"tooltip_zone_medium_cost_text_3"} />
                  </div>
                </div>
              )
            } as TypeTooltip
          },
          {
            title: "detail_zone_max_capacity_before_enhancement_label",
            content:
              zone.maxCapacityBeforeEnhancement ||
              zone.maxCapacityBeforeEnhancement === 0
                ? zone.maxCapacityBeforeEnhancement.toString()
                : "",
            unit: UNITS.NM3H
          },
          {
            title: "detail_zone_max_capacity_after_enhancement_label",
            content:
              zone.maxCapacityAfterEnhancement ||
              zone.maxCapacityAfterEnhancement === 0
                ? zone.maxCapacityAfterEnhancement.toString()
                : "",
            unit: UNITS.NM3H
          },
          {
            title: "detail_zone_connection_cost_label",
            content:
              zone.connectionCost || zone.connectionCost === 0
                ? zone.connectionCost.toString()
                : "",
            unit: UNITS.M
          },
          {
            title: "detail_zone_enhancement_cost_label",
            content:
              zone.enhancementCost || zone.enhancementCost === 0
                ? zone.enhancementCost.toString()
                : "",
            unit: UNITS.M
          },
          {
            title: "detail_zone_third_party_cost_label",
            content:
              zone.thirdPartyCost || zone.thirdPartyCost === 0
                ? zone.thirdPartyCost.toString()
                : "",
            unit: UNITS.M
          },
          {
            title: "detail_zone_project_in_service_label",
            content:
              zone.projectInService || zone.projectInService === 0
                ? zone.projectInService.toString()
                : ""
          },
          {
            title: "detail_zone_project_injection_signed_label",
            content:
              zone.projectInjectionSigned || zone.projectInjectionSigned === 0
                ? zone.projectInjectionSigned < 3
                  ? "< 3"
                  : zone.projectInjectionSigned.toString()
                : ""
          },
          {
            title: "detail_zone_injection_capacity_in_file_label",
            content:
              zone.injectionCapacityInFile || zone.injectionCapacityInFile === 0
                ? zone.injectionCapacityInFile.toString()
                : "",
            unit: UNITS.NM3H
          }
        ]
      : [];

  const sendMail = () => {
    if (!loading) {
      setLoading(true);
      const url = "/api/zones/" + zoneId + "/sendMail";
      api
        .postJSON(url, JSON.stringify({}))
        .then(() => {
          toast.success("Les mails ont été envoyés avec succès");
          navigate(ROUTE_HOME + "/" + zoneId);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const status = zone?.status as StatusZoneKeys;
  const statusZone = STATUT_ZONE[status];
  const zoneParentId = zone && zone.pcZoneParentId;
  const isSuperAdmin = user && user.role === ROLES.ROLE_SUPERADMIN.role;
  const showReopenButton = isSuperAdmin && statusZone === STATUT_ZONE.DELETED;
  const showDeleteButton = isSuperAdmin && statusZone !== STATUT_ZONE.DELETED;
  return (
    <Location>
      {({ location }) => (
        <>
          <Breadcrumb items={breadcrumb} newTab={isTokenValid} />
          {zone ? (
            <CardLayout
              title={zone.name}
              badge={statusZone?.description}
              variantBadge={statusZone?.variant}
              date={
                zone.consultationEndDate &&
                moment(zone.consultationEndDate).format(DATE_LITTERAL_FORMAT)
              }
              bodyClassName="px-4 pb-4 details-container"
              nextTitleComponent={
                isConnected ? (
                  <div className="buttons">
                    {zone.status &&
                      statusZone === STATUT_ZONE.CONSULTATION_DONE && (
                        <>
                          <ButtonIconBackward
                            icon={faFileArchive}
                            message="export_zip_button_label"
                            className="btn-tertiary m-2"
                            onClick={() => exportData(zoneId, zone?.name)}
                          />
                          <DuplicateZoneModal zoneId={zoneId} />
                          <ButtonIconBackward
                              icon={faPen}
                              className="m-2"
                              message="modify_zone_comment_button_label"
                              onClick={() =>
                                  navigate(location.pathname + PATH_EDIT)
                              }
                          />
                        </>
                      )}
                    {zone.status && statusZone === STATUT_ZONE.CONSULTATION && (
                      <ButtonIconBackward
                        icon={faMailBulk}
                        message="send_mail_button_label"
                        className={`m-2 btn-secondary f-basis-100 ${
                          loading ? "disabled" : ""
                        }`}
                        onClick={sendMail}
                      />
                    )}
                    {zone.status &&
                      statusZone !== STATUT_ZONE.CONSULTATION_DONE &&
                      statusZone !== STATUT_ZONE.DELETED && (
                        <ButtonIconBackward
                          icon={faPen}
                          className="m-2"
                          message="modify_zone_button_label"
                          onClick={() =>
                            navigate(location.pathname + PATH_EDIT)
                          }
                        />
                      )}
                    <ButtonIconBackward
                      icon={faList}
                      className="m-2"
                      variant="secondary"
                      message="statistics_button_label"
                      onClick={() => navigate(location.pathname + PATH_STATS)}
                    />
                    {showDeleteButton && (
                      <ButtonIconBackward
                        icon={faTrash}
                        className="m-2"
                        variant="danger"
                        message="delete_button_label"
                        onClick={() => deleteZone(zoneId as number)}
                      />
                    )}
                    {showReopenButton && (
                      <ButtonIconBackward
                        icon={faBookOpen}
                        className="m-2"
                        variant="info"
                        message="reopen_button_label"
                        onClick={() => reopenZone(zoneId as number)}
                      />
                    )}
                  </div>
                ) : null
              }
            >
              {zoneParentId && (
                <div>
                  <a
                    href={""}
                    className="font-primary"
                    onClick={event => {
                      event.preventDefault();
                      if (isTokenValid) {
                        window.open(`${ROUTE_HOME}/${zoneParentId}`, "_blank");
                      } else {
                        navigate(`${ROUTE_HOME}/${zoneParentId}`);
                      }
                    }}
                  >
                    <FormattedMessage
                      id={"detail_zone_consultation_zone_parent"}
                    />
                  </a>
                </div>
              )}
              {region && region.name && (
                <div className="pt-3">
                  <span className="font-weight-bold">
                    <FormattedMessage id="detail_zone_region_label" />
                  </span>
                  <span>{region && region.name}</span>
                </div>
              )}
              {zone.pcCantonResources && (
                <div>
                  <span className="font-weight-bold">
                    <FormattedMessage id="detail_zone_cantons_label" />
                  </span>
                  <span>
                    {zone.pcCantonResources &&
                      zone.pcCantonResources
                        .map(canton => canton.name)
                        .join(", ")}
                  </span>
                </div>
              )}
              {zone.stakeholders && (
                <div>
                  <span className="font-weight-bold">
                    <FormattedMessage id="detail_zone_stakeholders_label" />
                  </span>
                  <span>{zone.stakeholders}</span>
                </div>
              )}
              {zone.description && (
                <div className="container-radius bg-blue p-4 mb-3">
                  <div className="font-weight-bold pb-2">
                    <FormattedMessage id="detail_zone_description_label" />
                  </div>
                  <span>{zone.description && parse(zone.description)}</span>
                </div>
              )}
              <Row className="py-3">
                {zone.imageURL && (
                  <Col sm={4}>
                    <Image src={zone.imageURL} fluid onClick={()=> window.open(zone.imageURL, "_blank")} className="cursor-pointer highlight"/>
                  </Col>
                )}
                <Col sm={8} className="d-flex align-items-center mt-4 mt-md-0">
                  <UnorderedList className="w-100" items={listItems} />
                </Col>
              </Row>
              {zone.comment && (
                <div className="container-radius bg-blue p-4 mb-3">
                  <div className="font-weight-bold pb-2">
                    <FormattedMessage id="detail_zone_comment_label" />
                  </div>
                  <span>{zone.comment && parse(zone.comment)}</span>
                </div>
              )}
              {zoneId && (
                <Comments
                  comments={commentsStore.comments}
                  nbComments={commentsStore.count}
                  nbAvis={avisStore.count}
                  avis={avisStore.avis}
                  zoneId={"" + zoneId}
                  zoneStatus={zone?.status ? zone.status : ""}
                  isTokenValid={isTokenValid}
                />
              )}
            </CardLayout>
          ) : (
            <Loader />
          )}
        </>
      )}
    </Location>
  );
}

export default injectIntl(ZoneDetail);
