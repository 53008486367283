import React, { useState } from "react";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import ButtonIconBackward from "../button/ButtonIconBackward";
import CenteredModal from "./CenteredModal";
import { FormattedMessage } from "react-intl";
import { duplicateZone } from "../../state/effect";

type Props = {
  zoneId?: number;
};
const DuplicateZoneModal = ({ zoneId }: Props) => {
  const handleConfirm = () => {
    if (zoneId) {
      duplicateZone(zoneId);
      setShow(false);
    }
  };
  const [show, setShow] = useState(false);
  return (
    <>
      <ButtonIconBackward
        icon={faClone}
        message="duplicate_zone_button_label"
        className="btn-secondary m-2"
        onClick={() => {
          setShow(!show);
        }}
      />
      <CenteredModal
        show={show}
        idTitle={"duplicate_zone_modal_title"}
        onConfirm={handleConfirm}
        onCancel={() => setShow(false)}
      >
        <FormattedMessage id={"duplicate_zone_modal_content"} />
      </CenteredModal>
    </>
  );
};

export default DuplicateZoneModal;
