import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import React, { useEffect, useState } from "react";
import { TypeBreadcrumb, TypeZoneStats } from "../../constants/types";
import { InjectedIntl, injectIntl } from "react-intl";
import api from "../../network/api";
import UnorderedList from "../../components/liste/UnorderedList";

type Props = {
  path: string;
  intl: InjectedIntl;
  zoneId?: number;
};

function ZoneStats({ intl, zoneId }: Props) {
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
  const [stats, setStats] = useState({ numberOfVisit: 0 } as TypeZoneStats);

  // Effects
  useEffect(() => {
    const { formatMessage } = intl;
    const newBreadcrumb = [
      {
        id: "fil-ariane-saisie",
        label: formatMessage({ id: "breadcrumb_stats_zone_label" })
      }
    ];
    setBreadcrumb(newBreadcrumb);
  }, []);

  useEffect(() => {
    api.getJSON("/api/zones/" + zoneId + "/stats").then(json => setStats(json));
  }, [zoneId]);

  const listItems = [
    {
      title: "stats_number_of_opinion_label",
      content:
        stats?.numberOfOpinion || stats?.numberOfOpinion === 0
          ? stats.numberOfOpinion.toString()
          : ""
    },
    {
      title: "stats_number_of_visit_label",
      content:
        stats?.numberOfVisit || stats?.numberOfVisit === 0
          ? stats.numberOfVisit.toString()
          : ""
    },
    {
      title: "stats_stakeholders_label",
      content: stats?.stakeholders
        ? "<ul>" +
          stats.stakeholders
            .split(";")
            .map(
              stakeholder =>
                "<li>" +
                stakeholder.replace(/</g, "&lt;").replace(/>/g, "&gt;") +
                "</li>"
            )
            .join("") +
          "</ul>"
        : ""
    }
  ];
  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout
        idTitle="stats_zone_header_title"
        valuesTitle={{ name: stats?.name }}
        bodyClassName="px-4 pb-4"
      >
        <UnorderedList className="w-100" items={listItems} />
      </CardLayout>
    </>
  );
}

export default injectIntl(ZoneStats);
