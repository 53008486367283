import React from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import Form from "react-bootstrap/Form";

type Props = {
  name: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  accept?: string;
  onChange: (e: any) => void;
  error?: string;
  file?: File;
  buttonLabel?: string;
};

const FileInput = ({
  name,
  idLabel,
  valuesLabel,
  accept = "image/png, image/jpeg",
  onChange,
  error,
  file,
  buttonLabel = "parcourir_button_label"
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <label
        className={`btn btn-file ${!!error ? "is-invalid invalid" : ""} ${
          !!file ? "is-valid" : ""
        }`}
      >
        <Form.Control
          type="file"
          name={name}
          accept={accept}
          onChange={onChange}
        />
        <div className="d-flex h-100 justify-content-center align-items-center cursor-pointer">
          {file && file.name ? (
            file.name
          ) : (
            <FormattedMessage id={buttonLabel as string} />
          )}
        </div>
      </label>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  </>
);

export default FileInput;
