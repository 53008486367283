import React from "react";
import Form from "react-bootstrap/Form";

type Props = {
  name: string;
  label: string | React.ReactElement;
  value: boolean;
  onChange: (e: any) => void;
  error?: string;
};

const Checkbox = ({ name, label = "", value, onChange, error }: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Check
        className="mb-sm-3 mb-md-0"
        type="checkbox"
        name={name}
        checked={value}
        onChange={onChange}
        label={label}
        isInvalid={!!error}
      />
    </Form.Group>
  </>
);

export default Checkbox;
