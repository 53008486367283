import {PATH_EDIT, PATH_STATS, ROUTE_HOME, ROUTE_USER_DETAIL} from "./route";

export const DETAIL_ZONE = {
  id: "zone-details",
  libelle: "Détail d'une zone",
  url: ROUTE_HOME + "/{id}"
};
export const EDIT_ZONE = {
  id: "zone-edit",
  libelle: "Modifier une zone",
  url: ROUTE_HOME + "/{id}" + PATH_EDIT
};
export const STATS_ZONE = {
  id: "zone-stats",
  libelle: "Statistiques",
  url: ROUTE_HOME + "/{id}" + PATH_STATS
};
export const EXPORT_ZONE = {
  id: "zone-export",
  libelle: "Export de la zone",
  url: "/api/zones/{id}/report/zip"
};
export const DETAIL_USER = {
  id: "users-details",
  libelle: "Détails de l'utilisateur",
  url:  ROUTE_USER_DETAIL + "/{id}"
}

export const LIENS = [DETAIL_ZONE, EDIT_ZONE, EXPORT_ZONE, STATS_ZONE, DETAIL_USER];
