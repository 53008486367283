import React, { useState } from "react";
import parse from "html-react-parser";
import { ButtonDownloadAndSaveFile } from "../button/ButtonDownloadAndSaveFile";
import CenteredModal from "../modal/CenteredModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteAvis, deleteComment } from "../../state/effect";
import { COMMENT_TYPE } from "../../constants/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  commentId?: string;
  name?: string;
  functionName?: string;
  organization: string;
  opinion?: string;
  commentType?: string;
  filename?: string;
  ownComment?: boolean;
};

const Comment = ({
  commentId,
  name,
  functionName = "",
  organization = "",
  opinion = "",
  commentType = COMMENT_TYPE.COMMENT,
  filename,
  ownComment = false
}: Props) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  function handleDelete() {
    if (commentId) {
      if (commentType === COMMENT_TYPE.COMMENT) {
        deleteComment(commentId);
      } else if (commentType === COMMENT_TYPE.OPINION) {
        deleteAvis(commentId);
      }
    }
  }
  return (
    <>
      <div
        className={`commentaire bg-white py-3 px-4 my-2 ${ownComment &&
          "align-self-start"}`}
      >
        <div className="commentaire-title pb-1">
          {organization}
          {name && `, ${name}`}
          {functionName && `, ${functionName}`}
          {commentId && (
            <span
              className="ml-3 icon font-primary cursor-pointer"
              onClick={() => setShowModalConfirm(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </span>
          )}
        </div>
        <div>
          {opinion && parse(opinion)}
          {filename && (
            <ButtonDownloadAndSaveFile
              filename={filename}
              urlDownload={"/api/comments/" + commentId + "/file"}
            />
          )}
        </div>
        <CenteredModal
          show={showModalConfirm}
          idTitle="delete_confirm"
          onConfirm={() => {
            setShowModalConfirm(false);
            handleDelete();
          }}
          onCancel={() => {
            setShowModalConfirm(false);
          }}
        >
          {opinion && parse(opinion)}
          {filename && (
            <ButtonDownloadAndSaveFile
              filename={filename}
              urlDownload={"/api/comments/" + commentId + "/file"}
            />
          )}
        </CenteredModal>
      </div>
    </>
  );
};

export default Comment;
