import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import BreadcrumbItem from "./BreadcrumbItem";
import { TypeBreadcrumb } from "../../constants/types";
import { ROUTE_HOME } from "../../constants/route";

type Props = {
  intl: InjectedIntl;
  items: TypeBreadcrumb[];
  newTab?: boolean;
};

function FilAriane(props: Props) {
  const { items, intl, newTab } = props;
  const itemsLength = items.length;

  const { formatMessage } = intl;

  const filArianeHome = {
    id: "home-breadcrumb",
    url: ROUTE_HOME,
    label: formatMessage({ id: "fil_ariane_accueil" })
  };

  return (
    <nav>
      <ol aria-label="breadcrumb" className="fil-ariane">
        <BreadcrumbItem
          key={filArianeHome.id}
          item={filArianeHome}
          active={false}
          newTab={newTab}
        />
        {items.map((item: TypeBreadcrumb, i: number) => (
          <BreadcrumbItem
            key={item.id}
            item={item}
            active={itemsLength === i + 1}
            newTab={newTab}
          />
        ))}
      </ol>
    </nav>
  );
}

export default injectIntl(FilAriane);
