import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, MessageValue } from "react-intl";
import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";
import ButtonIconBackward from "../button/ButtonIconBackward";

type Props = {
  show: boolean;
  size?: "sm" | "lg" | "xl";
  idTitle: string;
  valuesTitle?: { [key: string]: MessageValue | JSX.Element };
  description?: string;
  children: ReactNode;
  labelBtnCancel?: string;
  labelBtnConfirm?: string;
  onCancel: () => void;
  onConfirm?: () => void;
};

const CenteredModal = ({
  show,
  size,
  idTitle,
  valuesTitle,
  description,
  children,
  labelBtnCancel = "cancel_button_label",
  labelBtnConfirm = "confirm_button_label",
  onCancel,
  onConfirm
}: Props) => {
  return (
    <>
      <Modal show={show} onHide={onCancel} size={size} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={idTitle} values={valuesTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <ButtonIconBackward
            icon={faBan}
            type="button"
            message={labelBtnCancel}
            variant="danger"
            onClick={onCancel}
          />
          <ButtonIconBackward
            icon={faCheck}
            type="submit"
            message={labelBtnConfirm}
            variant="primary"
            onClick={onConfirm}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CenteredModal;
