export const ROUTE_HOME = "/pc";
export const ROUTE_LEGAL_MENTIONS = ROUTE_HOME + "/legal-mentions";
export const ROUTE_LOGIN = ROUTE_HOME + "/login";

// Zone
export const PATH_EDIT = "/edit";
export const PATH_STATS = "/stats";
export const ROUTE_ZONE = ROUTE_HOME + "/*";
export const ROUTE_NEW = ROUTE_HOME + "/new";

// Admin
export const PATH_SPONSORSHIP = "/sponsorship";
export const PATH_USER_LIST = "/user-list";
export const PATH_USER_DETAIL = "/user"
export const PATH_PASSWORD = "/password";
export const PATH_PROFILE = "/profile";
export const ROUTE_ADMIN = ROUTE_HOME + "/admin";
export const PATH_ADMIN = ROUTE_ADMIN + "/*";
export const ROUTE_SPONSORSHIP = ROUTE_ADMIN + PATH_SPONSORSHIP;
export const ROUTE_PASSWORD = ROUTE_ADMIN + PATH_PASSWORD;
export const ROUTE_PROFILE = ROUTE_ADMIN + PATH_PROFILE;
export const ROUTE_USER_LIST = ROUTE_ADMIN + PATH_USER_LIST;
export const ROUTE_USER_DETAIL = ROUTE_ADMIN + PATH_USER_DETAIL;
