import React, { useEffect, useState } from "react";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import { Col, Form, Row } from "react-bootstrap";
import { InjectedIntl, injectIntl } from "react-intl";
import { TypeBreadcrumb } from "../../constants/types";
import TextInput from "../../components/form/TextInput";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { VALIDATION_ERRORS } from "../../constants/constant";
import api from "../../network/api";
import { toast } from "react-toastify";
import { ROUTE_USER_LIST } from "../../constants/route";
import { navigate } from "@reach/router";

type Props = {
  path: string;
  intl: InjectedIntl;
};

interface TypeForm {
  lastname?: string;
  firstname?: string;
  jobTitle?: string;
  company?: string;
  email?: string;
}

interface TypeError {
  lastname?: string;
  firstname?: string;
  jobTitle?: string;
  company?: string;
  email?: string;
}

type FormKeys = keyof TypeForm;

const Sponsorship = ({ intl }: Props) => {
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
  const [form, setForm] = useState({
    lastname: undefined,
    firstname: undefined,
    jobTitle: undefined,
    company: undefined,
    email: undefined
  } as TypeForm);
  const [loading, setLoading] = useState(false as boolean);
  const [errors, setErrors] = useState({} as TypeError);

  // Effects
  useEffect(() => {
    const { formatMessage } = intl;
    const newBreadcrumb = [
      {
        id: "fil-ariane-list",
        label: formatMessage({
          id: "breadcrumb_user_list_label"
        }),
        url: ROUTE_USER_LIST
      },
      {
        id: "fil-ariane-sponsorship",
        label: formatMessage({
          id: "breadcrumb_sponsorship_label"
        })
      }
    ];
    setBreadcrumb(newBreadcrumb);
  }, []);

  const handleChange = (event: any) => {
    const { target } = event;
    setForm({
      ...form,
      [target.name]: target.value
    });
  };

  const save = () => {
    if (!loading) {
      const errors = validate();
      setErrors(errors);
      const errorsKeys = Object.keys(errors) as FormKeys[];
      if (errorsKeys.length === 0) {
        setLoading(true);
        const url = "/api/users";
        api
          .postJSON(url, JSON.stringify(form))
          .then(() => {
            toast.success("Le mail a été envoyé avec succès.");
            navigate(ROUTE_USER_LIST);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const validate = () => {
    const errors = {} as TypeError;
    (Object.keys(form) as FormKeys[]).forEach((key: FormKeys) => {
      if (!form[key]) {
        errors[key] = VALIDATION_ERRORS.REQUIRED;
      }
    });
    return errors;
  };

  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout idTitle="sponsorship_title" bodyClassName="px-4 pb-4">
        <Col
          xs={12}
          sm={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          xl={{ span: 4, offset: 4 }}
        >
          <Form>
            <Row>
              <Col>
                <TextInput
                  idLabel="sponsorship_lastname"
                  name="lastname"
                  disabled={loading}
                  onChange={handleChange}
                  error={form.lastname ? "" : errors.lastname}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="sponsorship_firstname"
                  name="firstname"
                  disabled={loading}
                  onChange={handleChange}
                  error={form.firstname ? "" : errors.firstname}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="sponsorship_jobTitle"
                  name="jobTitle"
                  disabled={loading}
                  onChange={handleChange}
                  error={form.jobTitle ? "" : errors.jobTitle}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="sponsorship_company"
                  name="company"
                  disabled={loading}
                  onChange={handleChange}
                  error={form.company ? "" : errors.company}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="sponsorship_email"
                  name="email"
                  disabled={loading}
                  onChange={handleChange}
                  error={form.email ? "" : errors.email}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-5">
              <ButtonIconBackward
                icon={faSave}
                type="button"
                message="save_button_label"
                className={`mx-2 btn-tertiary ${loading ? "disabled" : ""}`}
                onClick={save}
              />
            </div>
          </Form>
        </Col>
      </CardLayout>
    </>
  );
};

export default injectIntl(Sponsorship);
