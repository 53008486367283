import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../form/TextInput";
import ButtonIconBackward from "../button/ButtonIconBackward";
import FileInput from "../form/FileInput";
import Checkbox from "../form/Checkbox";
import { postAvis } from "../../state/effect";
import { TypeValidation, TypeValidationMaxSize } from "../../constants/types";
import {
  COMMENT_MAX_SIZES,
  COMMENT_TYPE,
  MAX_FILE_SIZE_MB,
  VALIDATION_ERRORS
} from "../../constants/constant";
import { getQueryVariable } from "../../utils/utils";
import { AntiSpam } from "./AntiSpam";
import { ROUTE_LEGAL_MENTIONS } from "../../constants/route";

interface Props {
  zoneId: string;
}

type AvisFormErrors = {
  organization: string;
  cgu: string;
  filename: string;
  file: string;
};

type AvisFormState = {
  organization: string;
  cgu: boolean;
  filename: string;
  file: File | undefined;
};

type AvisFormStateKeys = keyof AvisFormState;

type AvisFormValidation = {
  organization: TypeValidationMaxSize;
  cgu: TypeValidation;
  file: TypeValidation;
  filename: TypeValidationMaxSize;
};

const AVIS_CREATION_FIELDS: AvisFormValidation = {
  organization: { required: true, maxSize: COMMENT_MAX_SIZES.organization },
  cgu: { required: true },
  filename: { required: true, maxSize: COMMENT_MAX_SIZES.filename },
  file: { required: true }
};

const INITIAL_AVIS: AvisFormState = {
  organization: "",
  cgu: false,
  filename: "",
  file: undefined
};

export function AvisForm({ zoneId }: Props) {
  const [avisForm, setAvisForm] = useState(INITIAL_AVIS);
  const [errors, setErrors] = useState({} as AvisFormErrors);
  const [isPrincipalView, setIsPrincipalView] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const token = getQueryVariable("token");

  const validate = () => {
    const errors_ = {} as AvisFormErrors;
    (Object.keys(avisForm) as AvisFormStateKeys[]).forEach(
      (key: AvisFormStateKeys) => {
        if (!avisForm[key] && AVIS_CREATION_FIELDS[key]?.required) {
          if (
            key === "file" &&
            avisForm[key]?.name &&
            !avisForm[key]?.name.toLowerCase().endsWith(".pdf")
          ) {
            errors_[key] = VALIDATION_ERRORS.PDF_FILE_REQUIRED;
          } else {
            errors_[key] = VALIDATION_ERRORS.REQUIRED;
          }
        } else if (
          avisForm[key] &&
          typeof avisForm[key] === "string" &&
          "maxSize" in AVIS_CREATION_FIELDS[key]
        ) {
          const value = avisForm[key] as string;
          const validation = AVIS_CREATION_FIELDS[key] as TypeValidationMaxSize;
          if (validation.maxSize < value.length) {
            errors_[
              key
            ] = `${VALIDATION_ERRORS.TOO_LONG} (${value.length} /${validation.maxSize})`;
          }
        } else if (key === ("file" as AvisFormStateKeys) && avisForm.file) {
          const fileSize = avisForm.file.size;
          if (fileSize > MAX_FILE_SIZE_MB * 1048576) {
            errors_[key] = `${VALIDATION_ERRORS.FILE_TOO_BIG} (${(
              fileSize / 1048576
            ).toFixed(2)} /${MAX_FILE_SIZE_MB}MB)`;
          }
        }
      }
    );
    return errors_;
  };

  function handleChange(event: any) {
    const { target } = event;
    setAvisForm({
      ...avisForm,
      [target.name.replace("avis_", "")]: target.value
    });
  }

  function handleChangeCheck(event: any) {
    const { target } = event;
    setAvisForm({
      ...avisForm,
      [target.name.replace("avis_", "")]: target.checked
    });
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.name && !file.name.toLowerCase().endsWith(".pdf")) {
        setAvisForm({ ...avisForm, file: undefined, filename: "" });
        setErrors({ ...errors, file: VALIDATION_ERRORS.PDF_FILE_REQUIRED });
      } else {
        setAvisForm({ ...avisForm, file: file, filename: file.name });
        setErrors({ ...errors, file: "" });
      }
    } else {
      setAvisForm({ ...avisForm, file: undefined, filename: "" });
      setErrors({ ...errors, file: "" });
    }
  };

  const submitForm = (event: any) => {
    event.preventDefault();
    const err = validate();
    setErrors(err);
    if (Object.keys(err).length === 0) {
      setIsPrincipalView(false);
      setIsSending(true);
      if (avisForm.file && avisForm.filename) {
        const formData = new FormData();
        formData.append("organization", avisForm.organization!);
        formData.append("cgu", "" + avisForm.cgu);

        formData.append("file", avisForm.file);
        formData.append("filename", avisForm.filename);
        formData.append("pcZoneId", "" + zoneId);
        formData.append("token", "" + token);
        formData.append("type", COMMENT_TYPE.OPINION);
        setAvisForm(INITIAL_AVIS);
        postAvis(formData).finally(() => setIsSending(false));
      }
    } else {
      throw "Validation error";
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="commentaire-form bg-white py-3 px-4 my-2 col-sm-6">
          <h4 className="pb-4 mb-0 text-primary">
            <FormattedMessage id="add_avis_label" />
          </h4>
          <AntiSpam
            antiSpamText={"add_other_avis"}
            isFirstView={isPrincipalView}
            setIsFirstView={setIsPrincipalView}
            bodyMessage="add_avis_message"
            isSending={isSending}
          >
            <Form onSubmit={submitForm}>
              <Form.Row>
                <Col sm={12} md={6}>
                  <TextInput
                    name="avis_organization"
                    idLabel="commentaire_organisation_label"
                    value={avisForm.organization}
                    onChange={handleChange}
                    placeholder={""}
                    error={!errors.organization ? "" : errors.organization}
                  />
                </Col>
                <Col sm={12} md={6} className="d-flex align-items-center">
                  <Checkbox
                    name="avis_cgu"
                    value={avisForm.cgu!}
                    onChange={handleChangeCheck}
                    error={avisForm.cgu ? "" : errors.cgu}
                    label={
                      <span>
                        <FormattedMessage id="commentaire_cgu_1_label" />
                        <a
                          rel="noopener noreferrer"
                          href={ROUTE_LEGAL_MENTIONS}
                          target="_blank"
                        >
                          <FormattedMessage id="commentaire_cgu_url_label" />
                        </a>
                        <FormattedMessage id="commentaire_cgu_2_label" />
                      </span>
                    }
                  />
                </Col>
              </Form.Row>
              <FileInput
                name="avis_file"
                idLabel="commentaire_piece_jointe_label"
                valuesLabel={{ number: MAX_FILE_SIZE_MB }}
                accept="application/pdf, application/x-pdf"
                onChange={handleFileChange}
                error={
                  !errors.file
                    ? !errors.filename
                      ? ""
                      : errors.filename
                    : errors.file
                }
                file={avisForm.file}
              />
              <div className="d-flex justify-content-end mt-4">
                <ButtonIconBackward
                  icon={faPaperPlane}
                  type="submit"
                  message="post_avis_label"
                  variant="primary"
                />
              </div>
            </Form>
          </AntiSpam>
        </div>
      </div>
    </>
  );
}
