import React, { useState } from "react";
import {
  TypeComment,
  TypeValidation,
  TypeValidationMaxSize
} from "../../constants/types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Checkbox from "../form/Checkbox";
import { FormattedMessage } from "react-intl";
import ButtonIconBackward from "../button/ButtonIconBackward";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import FileInput from "../form/FileInput";
import RichText from "../form/RichText";
import TextInput from "../form/TextInput";
import { postComment, postCommentWithFile } from "../../state/effect";
import {
  COMMENT_MAX_SIZES,
  COMMENT_TYPE,
  MAX_FILE_SIZE_MB,
  VALIDATION_ERRORS
} from "../../constants/constant";
import { AntiSpam } from "./AntiSpam";
import { ROUTE_LEGAL_MENTIONS } from "../../constants/route";

type Props = {
  zoneId: string;
};

type CommentFormErrors = {
  name: string;
  functionName: string;
  organization: string;
  cgu: string;
  opinion: string;
  file: string;
  filename: string;
};

type CommentFormState = {
  name: string;
  functionName: string;
  organization: string;
  cgu: boolean;
  opinion: string;
  file?: File;
  filename: string;
};

type CommentFormStateKeys = keyof CommentFormState;

type CommentFormValidation = {
  name: TypeValidationMaxSize;
  functionName: TypeValidationMaxSize;
  organization: TypeValidationMaxSize;
  cgu: TypeValidation;
  opinion: TypeValidationMaxSize;
  file: TypeValidation;
  filename: TypeValidationMaxSize;
};

const COMMENT_CREATION_FIELDS: CommentFormValidation = {
  cgu: { required: true },
  file: { required: false },
  filename: { required: false, maxSize: COMMENT_MAX_SIZES.filename },
  functionName: { required: true, maxSize: COMMENT_MAX_SIZES.functionName },
  name: { required: true, maxSize: COMMENT_MAX_SIZES.name },
  opinion: { required: true, maxSize: COMMENT_MAX_SIZES.opinion },
  organization: { required: true, maxSize: COMMENT_MAX_SIZES.organization }
};

const INITIAL_COMMENT: CommentFormState = {
  name: "",
  functionName: "",
  organization: "",
  cgu: false,
  opinion: "",
  file: undefined,
  filename: ""
};

function CommentaireForm({ zoneId }: Props) {
  // States
  const [comment, setComment] = useState(INITIAL_COMMENT);
  const [errors, setErrors] = useState({} as CommentFormErrors);
  const [isPrincipalView, setIsPrincipalView] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const acceptedTypes =
    "application/pdf, application/x-pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg";

  const handleChange = (event: any) => {
    const { target } = event;
    setComment({ ...comment, [target.name]: target.value });
  };

  const handleChangeCheck = (event: any) => {
    const { target } = event;
    setComment({ ...comment, [target.name]: target.checked });
  };

  function checkFileType(file: File) {
    return acceptedTypes.toLowerCase().includes(file.type.toLowerCase());
  }

  const handleFileChange = (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      if (file.name && !checkFileType(file)) {
        setComment({ ...comment, file: undefined, filename: "" });
        setErrors({
          ...errors,
          file:
            VALIDATION_ERRORS.INVALID_FILE_TYPE + "PDF, Word, Excel, JPG, PNG"
        });
      } else {
        setComment({ ...comment, file: file, filename: file.name });
        setErrors({ ...errors, file: "" });
      }
    } else {
      setComment({ ...comment, file: undefined, filename: "" });
      setErrors({ ...errors, file: "" });
    }
  };

  const validate = () => {
    const errors_ = {} as CommentFormErrors;
    (Object.keys(comment) as CommentFormStateKeys[]).forEach(
      (key: CommentFormStateKeys) => {
        if (!comment[key] && COMMENT_CREATION_FIELDS[key]?.required) {
          errors_[key] = VALIDATION_ERRORS.REQUIRED;
        } else if (
          comment[key] &&
          typeof comment[key] === "string" &&
          "maxSize" in COMMENT_CREATION_FIELDS[key]
        ) {
          const value = comment[key] as string;
          const validation = COMMENT_CREATION_FIELDS[
            key
          ] as TypeValidationMaxSize;
          if (validation.maxSize < value.length) {
            errors_[
              key
            ] = `${VALIDATION_ERRORS.TOO_LONG} (${value.length} /${validation.maxSize})`;
          }
        } else if (key === ("file" as CommentFormStateKeys) && comment.file) {
          const fileSize = comment.file.size;
          if (fileSize > MAX_FILE_SIZE_MB * 1048576) {
            errors_[key] =
              VALIDATION_ERRORS.FILE_TOO_BIG +
              `(${(fileSize / 1048576).toFixed(2)} /${MAX_FILE_SIZE_MB}MB)`;
          }
        }
      }
    );
    return errors_;
  };

  const submitForm = (event: any) => {
    event.preventDefault();
    const err = validate();
    setErrors(err);
    if (Object.keys(err).length === 0) {
      setIsPrincipalView(false);
      setIsSending(true);
      if (comment.file && comment.filename) {
        const formData = new FormData();
        formData.append("name", comment.name!);
        formData.append("function", comment.functionName!);
        formData.append("organization", comment.organization!);
        formData.append("cgu", "" + comment.cgu);
        formData.append("opinion", "" + comment.opinion);

        formData.append("file", comment.file);
        formData.append("filename", comment.filename);
        formData.append("pcZoneId", "" + zoneId);
        formData.append("type", COMMENT_TYPE.COMMENT);
        setComment(INITIAL_COMMENT);
        postCommentWithFile(formData).finally(() => setIsSending(false));
      } else {
        const commentaire: TypeComment = { ...comment, zoneId };
        setComment(INITIAL_COMMENT);
        postComment(commentaire).finally(() => setIsSending(false));
      }
    } else {
      throw "Validation error";
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="commentaire-form bg-white py-3 px-4 my-2 col-sm-6">
          <h4 className="pb-4 mb-0 text-primary">
            <FormattedMessage id="add_commentaire_label" />
          </h4>
          <AntiSpam
            antiSpamText={"add_other_comment"}
            isFirstView={isPrincipalView}
            setIsFirstView={setIsPrincipalView}
            bodyMessage="add_commentaire_message"
            isSending={isSending}
          >
            <Form onSubmit={submitForm}>
              <Form.Row>
                <Col sm={12} md={6}>
                  <TextInput
                    name="name"
                    idLabel="commentaire_nom_prenom_label"
                    value={comment.name!}
                    onChange={handleChange}
                    error={!errors.name ? "" : errors.name}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextInput
                    name="functionName"
                    idLabel="commentaire_fonction_label"
                    value={comment.functionName!}
                    onChange={handleChange}
                    error={!errors.functionName ? "" : errors.functionName}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={12} md={6}>
                  <TextInput
                    name="organization"
                    idLabel="commentaire_organisation_label"
                    value={comment.organization}
                    onChange={handleChange}
                    error={!errors.organization ? "" : errors.organization}
                  />
                </Col>
                <Col sm={12} md={6} className="d-flex align-items-center">
                  <Checkbox
                    name="cgu"
                    value={comment.cgu!}
                    onChange={handleChangeCheck}
                    error={comment.cgu ? "" : errors.cgu}
                    label={
                      <span>
                        <FormattedMessage id="commentaire_cgu_1_label" />
                        <a
                          rel="noopener noreferrer"
                          href={ROUTE_LEGAL_MENTIONS}
                          target="_blank"
                        >
                          <FormattedMessage id="commentaire_cgu_url_label" />
                        </a>
                        <FormattedMessage id="commentaire_cgu_2_label" />
                      </span>
                    }
                  />
                </Col>
              </Form.Row>
              <RichText
                name="opinion"
                idLabel="commentaire_commentaire_label"
                value={comment.opinion}
                onChange={handleChange}
                error={!errors.opinion ? "" : errors.opinion}
              />
              <FileInput
                name="file"
                idLabel="commentaire_piece_jointe_label"
                valuesLabel={{ number: MAX_FILE_SIZE_MB }}
                accept={acceptedTypes}
                onChange={handleFileChange}
                file={comment.file}
                error={
                  !errors.file
                    ? !errors.filename
                      ? ""
                      : errors.filename
                    : errors.file
                }
              />
              <div className="d-flex justify-content-end mt-4">
                <ButtonIconBackward
                  icon={faPaperPlane}
                  type="submit"
                  message="post_commentaire_label"
                  variant="primary"
                />
              </div>
            </Form>
          </AntiSpam>
        </div>
      </div>
    </>
  );
}

export default CommentaireForm;
