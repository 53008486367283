import React from "react";
import ListeAction from "./ListeAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getLienById } from "../../utils/LienUtils";
import { exportData } from "../../utils/utils";
import { STATUT_ZONE } from "../../constants/constant";
import { StatusZoneKeys } from "../../constants/types";

type Props = {
  id: number;
  lienPrefix: string;
  name: string;
  zoneStatus: StatusZoneKeys;
};

function ListeBarreActions({ id, lienPrefix, name, zoneStatus }: Props) {
  const lienEdit = getLienById(`${lienPrefix}-edit`);
  const lienStats = getLienById(`${lienPrefix}-stats`);
  const showExport: boolean =
    STATUT_ZONE[zoneStatus] === STATUT_ZONE.CONSULTATION_DONE;
  return (
    <>
      {lienEdit && <ListeAction id={id} type="edit" lien={lienEdit} />}
      {lienStats && <ListeAction id={id} type="stats" lien={lienStats} />}
      {showExport && (
        <span className="action-liste" onClick={() => exportData(id, name)}>
          <FontAwesomeIcon icon={faDownload} />
        </span>
      )}
    </>
  );
}

export default ListeBarreActions;
