import { createEffect } from "effector";
import api from "../network/api";
import { toast } from "react-toastify";
import {
  FilterKeys,
  TypeAvis,
  TypeComment,
  TypeFilter
} from "../constants/types";
import { COMMENT_TYPE } from "../constants/constant";
import { navigate } from "@reach/router";
import { ROUTE_HOME } from "../constants/route";
import { disconnect } from "./event";

interface LoginParamType {
  username: string;
  password: string;
}

export const login = createEffect("authenticate", {
  async handler({ username, password }: LoginParamType) {
    const url = "/authenticate";
    const JSONBody = { username: username, password: password };
    return await api.postJSON(url, JSON.stringify(JSONBody));
  }
});

login.fail.watch(({ params, error }) => {
  console.log(params);
  console.log(error);
});

login.finally.watch(data => {
  if (data.status === "done") {
    const { params, result } = data;
    console.log(params); // {name: 'zerobias'}
    console.log(result); // resolved value
  } else {
    const { params, error } = data;
    console.error(params); // {name: 'zerobias'}
    console.error(error); // rejected value
  }
});

// subscribe to handler resolve
login.done.watch(({ params, result }) => {
  console.log(params); // {name: 'zerobias'}
  console.log(result); // resolved value
});

// subscribe to pending store status
login.pending.watch(pending => {
  console.log(pending); // false
});

export const getRegions = createEffect("getRegions", {
  async handler() {
    return await api.getJSON("/api/regions");
  }
});

interface CommentsListType {
  count: number;
  comments: TypeComment[];
}

export const getCommentsFromZone = createEffect("getCommentsFromZone", {
  async handler(zoneID: number) {
    const url = "/api/comments/zone/" + zoneID + "/list";
    const result: CommentsListType = await api.getJSON(url);
    const { count, comments } = result;
    if (comments && comments.map) {
      const commentsMapped: TypeComment[] = comments.map((comment: any) => ({
        ...comment,
        functionName: comment["function"] as string
      }));
      return { count, comments: commentsMapped };
    }
    return { count, comments: [] as TypeComment[] };
  }
});

export const postComment = createEffect("postComment", {
  async handler(comment: TypeComment) {
    const url = "/api/comments/";
    const JSONBody = JSON.stringify(typeCommentToCommentAddResource(comment));
    return await api.postJSON(url, JSONBody);
  }
});

function typeCommentToCommentAddResource(comment: TypeComment) {
  return {
    ...comment,
    pcZoneId: comment.zoneId,
    function: comment.functionName,
    type: COMMENT_TYPE.COMMENT
  };
}

export const postCommentWithFile = createEffect("postCommentWithFile", {
  async handler(formData: FormData) {
    const url = "/api/comments/";
    return await api.postFormData(url, formData);
  }
});

interface AvisListType {
  count: number;
  avis: TypeAvis[];
}

export const getAvisFromZone = createEffect("getAvisFromZone", {
  async handler(zoneID: number) {
    const url = "/api/avis/zone/" + zoneID + "/list";
    const result: AvisListType = await api.getJSON(url);
    const { count, avis } = result;
    if (avis && avis.map) {
      return { count, avis };
    }
    return { count, avis: [] as TypeAvis[] };
  }
});

export const postAvis = createEffect("postAvis", {
  async handler(formData: FormData) {
    const url = "/api/avis/";
    return await api.postFormData(url, formData);
  }
});

export const deleteComment = createEffect("deleteComment", {
  async handler(commentId: string) {
    return await api.delete("/api/comments/" + commentId);
  }
});

export const deleteAvis = createEffect("deleteAvis", {
  async handler(avisId: string) {
    return await api.delete("/api/comments/" + avisId);
  }
});

export const getZoneDetail = createEffect("getZoneDetail", {
  async handler(zoneId: number) {
    const url = "/api/zones/" + zoneId;
    return api.getJSON(url).catch(() => navigate(ROUTE_HOME));
  }
});

export const duplicateZone = createEffect("duplicateZone", {
  async handler(zoneId: number) {
    const url = `/api/zones/${zoneId}/duplicate`;
    const res = await api.postJSON(url, "");
    if (res.status === 201) {
      const id = res.headers.get("x-entity-id");
      if (id) {
        navigate(`/pc/${id}`);
      }
    }
  }
});

export const deleteZone = createEffect("deleteZone", {
  async handler(zoneId: number) {
    const url = `/api/zones/${zoneId}/delete`;
    api.postJSON(url, "").then(() => {
      getZoneDetail(zoneId);
      toast.success("La zone a été supprimée");
    });
  }
});

export const reopenZone = createEffect("reopenZone", {
  async handler(zoneId: number) {
    const url = `/api/zones/${zoneId}/reopen`;
    api.postJSON(url, "").then(() => {
      getZoneDetail(zoneId);
      toast.success("La zone est réouverte");
    });
  }
});

export const getIsTokenValid = createEffect("getIsTokenValid", {
  async handler(token: string) {
    return api.getJSON("/api/tokens/check/" + token).then(res => !!res);
  }
});

export const setIsTokenValid = createEffect("setIsTokenValid", {
  async handler(isTokenValid: boolean) {
    return isTokenValid;
  }
});

export const getUser = createEffect("getUser", {
  async handler() {
    const result = await api.getJSON("/api/users");
    if (result.email !== sessionStorage.getItem("email")) {
      disconnect();
      return null;
    }
    return result;
  }
});

export const getUserDetail = createEffect("getUserDetail", {
  async handler(userId: number) {
    const url = "/api/users/" + userId;
    return api.getJSON(url).catch(() => navigate(ROUTE_HOME));
  }
});

export const setFilter = createEffect("setFilter", {
  async handler(filter: FilterKeys) {
    return filter;
  }
});
