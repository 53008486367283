import React, { DetailedReactHTMLElement } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import parse from "html-react-parser";
import Tooltip from "../button/Tooltip";
import { TypeTooltip } from "../../constants/types";

type Props = {
  intl: InjectedIntl;
  title: string;
  content:
    | string
    | DetailedReactHTMLElement<{}, HTMLElement>
    | DetailedReactHTMLElement<{}, HTMLElement>[];
  total?: JSX.Element;
  tooltip?: TypeTooltip;
};

const UnorderedListItem = ({ intl, title, content, total, tooltip }: Props) => {
  const { formatMessage } = intl;
  return (
    <>
      <li className="mb-2">
        <span className="font-weight-bold">
          {parse(formatMessage({ id: title }))}
          {tooltip && <Tooltip {...tooltip} />}
        </span>
        <span>{content}</span>
        {total && <span>{total}</span>}
      </li>
    </>
  );
};

export default injectIntl(UnorderedListItem);
