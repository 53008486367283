import React from "react";
import { Col, Container, Row } from "reactstrap";
import UserMenu from "./UserMenu";
import ButtonIconForward from "./button/ButtonIconForward";
import { navigate } from "@reach/router";
import { ROUTE_HOME, ROUTE_LOGIN } from "../constants/route";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import { useStore } from "effector-react";
import { isTokenValidStore } from "../state/store";

type Props = { intl: InjectedIntl };

const Header = ({ intl }: Props) => {
  // Stores
  const isTokenValid = useStore(isTokenValidStore);

  const isConnected = sessionStorage && sessionStorage.token;
  const { formatMessage } = intl;
  const pathname = window.location.pathname;
  return (
    <div className="header">
      <Container>
        <Row className="align-items-center justify-content-end flex-nowrap flex-column-reverse flex-md-row">
          <Col
            onClick={() => {
              if (isTokenValid) {
                window.open(ROUTE_HOME, "_blank");
              } else {
                navigate(ROUTE_HOME);
              }
            }}
            xs="auto"
            className="flex-grow-1 cursor-pointer"
          >
            <h1>
              <FormattedMessage id="title" />
            </h1>
          </Col>
          <Col xs="auto" className="px-2 align-self-end mb-3 mb-md-0">
            {isConnected && <UserMenu />}
            {!isConnected && !pathname.startsWith(ROUTE_LOGIN) && (
              <ButtonIconForward
                message={formatMessage({ id: "login_button" })}
                onClick={() => {
                  if (isTokenValid) {
                    window.open(ROUTE_LOGIN, "_blank");
                  } else {
                    navigate(ROUTE_LOGIN);
                  }
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default injectIntl(Header);
