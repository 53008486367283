import React from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import Form from "react-bootstrap/Form";

type Props = {
  name?: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  value?: string;
  onChange: (e: any) => void;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: "text" | "password";
};

const TextInput = ({
  name,
  idLabel,
  valuesLabel,
  value,
  onChange,
  placeholder,
  error,
  disabled = false,
  type = "text"
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <Form.Control
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  </>
);

export default TextInput;
