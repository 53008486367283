import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

type Props = {
  path: string;
  component: Element | any;
};

const HeaderTopPage = ({ component: Component, ...rest }: Props) => (
  <div className="global no-focus-outline">
    <div className="container-no-side-menu">
      <Header />
      <div className="container-content">
        <Component {...rest} />
      </div>
      <Footer />
    </div>
  </div>
);

export default HeaderTopPage;
