import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "@reach/router";
import { TypeBreadcrumb } from "../../constants/types";

type Props = {
  item: TypeBreadcrumb;
  active: boolean;
  newTab?: boolean;
};

function FilArianeItem({ item, active = false, newTab = false }: Props) {
  return (
    <li
      className="fil-ariane-item"
      {...(active ? { "aria-current": "page" } : {})}
    >
      {active ? (
        <span className="active">{item.label}</span>
      ) : (
        <span>
          {newTab ? (
            <a href={item.url ? item.url : ""} target="_blank">
              {item.label}
            </a>
          ) : (
            <Link to={item.url ? item.url : ""}>{item.label}</Link>
          )}
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      )}
    </li>
  );
}

export default FilArianeItem;
