const Messages = {
  fil_ariane_accueil: "Accueil",
  title: "PLATEFORME DE CONSULTATION DES ZONAGES DE RACCORDEMENT BIOMÉTHANE",

  // Common
  logo_alt: "logo",
  copyright:
    "Copyright © 2020 Plateforme de consultation. Tous droits réservés.",
  yes: "Oui",
  no: "Non",
  legal_mentions_title: "Mentions légales",
  legal_mentions_page_title: "Conditions générales d'utilisation",

  // Table
  table_previous_text: "Précédent",
  table_next_text: "Suivant",
  table_loading_text: "Chargement...",
  table_no_data_text:
    "Aucun enregistrement correspondant au(x) filtre(s) n'a pu être trouvé",
  table_no_result_text: "Aucun résultat",
  table_page_text: "Page",
  table_of_text: "sur",
  table_rows_text: "lignes par page",
  default_filter_placeholder: "Filtrer les résultats de la recherche",

  // Form
  required_field_feedback: "Champ obligatoire",
  delete_confirm: "Êtes-vous sûr de vouloir supprimer l'élément ?",

  // Form
  commentaires_label: "Commentaires",
  nb_commentaires_label_singular: "{nbCommentaires} commentaire",
  nb_commentaires_label_plural: "{nbCommentaires} commentaires",
  avis_label: "Avis",
  nb_avis_label: "{nbAvis} avis",
  add_commentaire_label: "Ajouter un commentaire",
  add_commentaire_message: " Merci, votre commentaire a bien été envoyé.",
  add_other_comment: "Ajouter un autre commentaire",
  add_other_avis: "Ajouter un autre avis",
  add_avis_label: "Ajouter un avis",
  sending_message: " Envoi en cours...",
  add_avis_message: " Merci, votre avis a bien été envoyé.",
  commentaire_nom_prenom_label: "Nom & Prénom",
  commentaire_fonction_label: "Fonction",
  commentaire_organisation_label: "Organisation",
  commentaire_cgu_1_label: "J'accepte les ",
  commentaire_cgu_url_label: "conditions d'utilisation du site",
  commentaire_cgu_2_label: " et la gestion de mes informations personnelles",
  commentaire_commentaire_label: "Commentaire",
  commentaire_piece_jointe_label: "Pièce jointe (max {number}Mo)",
  post_commentaire_label: "Poster mon commentaire",
  post_avis_label: "Poster mon avis",

  // Buttons
  see_button_label: "Voir",
  modify_zone_button_label: "Modifier la zone",
  modify_zone_comment_button_label: "Modifier la synthèse",
  statistics_button_label: "Statistiques",
  duplicate_zone_button_label: "Réouvrir la zone",
  validate_button_label: "Valider",
  next_button_label: "Suivant",
  previous_button_label: "Précédent",
  save_button_label: "Enregistrer",
  cancel_button_label: "Annuler",
  reopen_button_label: "Réouvrir",
  confirm_button_label: "Confirmer",
  parcourir_button_label: "Parcourir",
  edit_file_button_label: "Modifier la carte",
  delete_button_label: "Supprimer",
  resend_mail_button_label: "Renvoyer le mail",
  export_excel_button_label: "Exporter au format XLSX",
  export_pdf_button_label: "Exporter au format PDF",
  export_zip_button_label: "Exporter au format ZIP",
  sponsorship_button_label: "Créer un nouvel administrateur",
  send_mail_button_label: "Envoyer les mails aux personnes à consulter",
  // NOSONAR
  password_button_label: "Modifier le mot de passe",
  login_button: "Connexion",

  // User Menu
  profil_button_label: "Mon profil",
  admin_button_label: "Tableau de bord Admin",
  parrainage_button_label: "Parrainage",
  deconnexion_button_label: "Déconnexion",
  connexion_button_label: "Connexion",
  login_button_label: "Login",
  mdp_button_label: "Mot de passe",

  // Régions
  co_label: "Centre-Ouest",
  so_label: "Sud-Ouest",
  e_label: "Est",
  no_label: "Nord-Ouest",
  se_label: "Sud-Est",
  idf_label: "Ile-de-France",

  // Statut Zones
  en_cours_consultation_label: "En cours de consultation",
  en_cours_creation_label: "En cours de création",
  consultation_terminee_label: "Consultation terminée",

  // Liste Zones
  breadcrumb_liste_zone_label: "Tableau de bord",
  zone_liste_presentation_title: "Plateforme de consultation",
  zone_liste_header_title: "Tableau de bord",
  zone_liste_button_new_label: "Créer une zone",
  zone_liste_dropdown_label: "Région : ",
  zone_liste_search_placeholder: "Rechercher par nom de zone, canton, commune ...",
  header_nom_zone_label: "Nom de la zone",
  header_region_label: "Région principale",
  header_statut_label: "Statut",
  header_maj_label: "Mise à jour",
  header_auteur_label: "Auteur",
  header_fin_consultation_label: "Fin de consultation",
  header_stakeholders_mail_date_label: "Envoi des mails",
  header_nb_comment_label: "Commentaires",
  header_nb_opinion_label: "Avis",

  // Details Zones
  breadcrumb_details_zone_label: "Détails d'une zone",
  detail_zone_cantons_label: "Liste des cantons (avant réforme de 2013) : ",
  detail_zone_description_label: " ",
  detail_zone_region_label: "Région administrative principale : ",
  detail_zone_deposit_potential_label:
    "Potentiel méthanisable de la zone par gisement : ",
  detail_zone_iv_value_label: "Valeur du I/V : ",
  detail_zone_medium_cost_label:
    "Niveau de coût moyen de raccordement des producteurs ",
  detail_zone_max_capacity_before_enhancement_label:
    "Capacité maximale d'accueil <u>avant compression</u> : ",
  detail_zone_max_capacity_after_enhancement_label:
    "Capacité maximale d'accueil <u>après compression</u> : ",
  detail_zone_connection_cost_label:
    "Montant estimé des investissements de <u>raccordement</u> sur la zone : ",
  detail_zone_enhancement_cost_label:
    "Montant estimé des investissements de <u>renforcement</u> sur la zone : ",
  detail_zone_third_party_cost_label:
    "Montant estimé de la participation de tiers : ",
  detail_zone_project_in_service_label:
    "Nombre de projets en service (Registre D8) : ",
  detail_zone_project_injection_signed_label:
    "Nombre de projets en cours (Registre D2-D7) : ",
  detail_zone_injection_capacity_in_file_label:
    "Capacité d'injection dans le registre tous opérateurs confondus : ",
  detail_zone_stakeholders_label: "Coordonnées des personnes à consulter : ",
  detail_zone_pre_consultation_end_date_label:
    "Date de fin de la pré-consultation : ",
  detail_zone_consultation_end_date_label: "La consultation se termine le ",
  detail_zone_stakeholders_mail_date_label:
    "Date d'envoi des mails aux parties prenantes : ",
  detail_zone_comment_label: "Synthèse de la consultation : ",
  detail_zone_consultation_zone_parent: "Accéder à l'ancienne consultation",
  tooltip_zone_medium_cost_title: "Détail",
  tooltip_zone_medium_cost_text_1: "€ : moins de 2\u00A0500€/Nm3",
  tooltip_zone_medium_cost_text_2:
    "€€ : entre 2\u00A0500€/Nm3 et 5\u00A0000€/Nm3",
  tooltip_zone_medium_cost_text_3: "€€€ : plus de 5\u00A0000€/Nm3",

  // Création Zones
  create_zone_identification_zone_label:
    "Rentrez les informations d'identification de la zone\u00A0:",
  create_zone_core_label:
    "Rentrez les informations principales de la zone\u00A0:",
  breadcrumb_create_zone_label: "Création d'une zone",
  create_zone_header_title: "Créer une zone",
  create_zone_informations_zone_label:
    "Rentrez les informations relatives à la zone\u00A0:",
  create_zone_nom_label: "Nom de la zone",
  create_zone_reference_label: "Référence de la zone",
  create_zone_description_label: "Description de la consultation",
  create_zone_cantons_label: "Liste des cantons (avant réforme de 2013)",
  create_zone_region_label: "Région administrative principale",
  create_zone_deposit_potential_label:
    "Potentiel méthanisable de la zone par gisement",
  create_zone_deposit_potential_type_label: "Type de gisement",
  create_zone_deposit_potential_value_label: "Potentiel méthanisable",
  create_zone_iv_value_label: "Valeur du I/V",
  create_zone_medium_cost_label:
    "Niveau de coût moyen de raccordement des producteurs ",
  create_zone_max_capacity_before_enhancement_label:
    "Capacité maximale d'accueil avant compression",
  create_zone_max_capacity_after_enhancement_label:
    "Capacité maximale d'accueil après compression",
  create_zone_connection_cost_label:
    "Montant estimé des investissements de raccordement sur la zone",
  create_zone_enhancement_cost_label:
    "Montant estimé des investissements de renforcement sur la zone",
  create_zone_third_party_cost_label:
    "Montant estimé de la participation de tiers",
  create_zone_project_in_service_label:
    "Nombre de projets en service (Registre D8)",
  create_zone_project_injection_signed_label:
    "Nombre de projets en cours (Registre D2-D7)",
  create_zone_injection_capacity_in_file_label:
    "Capacité d'injection dans le registre tous opérateurs confondus",
  create_zone_maillages_label: "Cartographie de zonage",
  create_zone_comment_label: "Synthèse de la consultation",

  create_zone_informations_consultation_label:
    "Rentrez les informations relatives à la consultation\u00A0:",
  create_zone_date_fin_pre_consultation_label:
    "Date de fin de pré-consultation",
  create_zone_date_fin_consultation_label: "Date de fin de consultation",
  create_zone_stakeholders_label:
    "Coordonnées des personnes à consulter (séparées par des ';')",
  create_zone_pre_consultation_btn_label: "Lancer la pré-consultation",
  create_zone_pre_consultation_modal_title: "Lancer la pré-consultation",
  create_zone_consultation_btn_label: "Lancer la consultation",
  create_zone_edit_consultation_btn_label:
    "Modifier la date de fin de consultation",
  create_zone_consultation_modal_title: "Lancer la consultation",
  create_zone_edit_consultation_modal_title:
    "Modifier la date de fin de consultation",

  // Modification Zones
  breadcrumb_edit_zone_label: "Modification d'une zone",
  edit_zone_header_title: "Modification de la zone {name}",

  empty_search: "Entrer un nom de canton à rechercher",
  no_result: "Aucun résultat",
  loading: "Chargement",
  total_deposit_potential: "Total du potentiel méthanisable",

  //Duplication Modal
  duplicate_zone_modal_content:
    "Attention cette fonctionnalité est destinée uniquement pour ouvrir une consultation sur une zone dont la précédente consultation est déjà terminée",
  duplicate_zone_modal_title: "Réouverture d'une zone",

  // Statistiques zone
  breadcrumb_stats_zone_label: "Statistiques d'une zone",
  stats_zone_header_title: "Statistiques de la zone {name}",
  stats_number_of_opinion_label: "Nombre d'avis: ",
  stats_number_of_visit_label: "Nombre de visites sur la page: ",
  stats_stakeholders_label: "Liste des parties prenantes: ",

  // Sponsorship
  breadcrumb_sponsorship_label: "Parrainage",
  sponsorship_title: "Parrainage",
  sponsorship_lastname: "Nom",
  sponsorship_firstname: "Prénom",
  sponsorship_jobTitle: "Fonction",
  sponsorship_company: "Société",
  sponsorship_email: "Mail",

  // User List
  breadcrumb_user_list_label: "Liste des utilisateurs",
  user_list_header_title: "Liste des utilisateurs",
  user_list_search_label: "Recherche : ",
  user_list_search_placeholder: "Rechercher par nom d'utilisateur, rôle...",
  header_firstname_user_list: "Prénom",
  header_lastname_user_list: "Nom",
  header_email_user_list: "email",
  header_role_user_list: "Rôle",
  header_jobTitle_user_list: "Fonction",
  header_company_user_list: "Entreprise",
  header_inactive_user_list: "Inactif",
  header_suspended_user_list: "Suspendu",
  header_validated_user_list: "Compte activé",
  suspend_confirm: "Êtes-vous sûr de vouloir suspendre l'utilisateur ?",
  rehabilitate_confirm: "Êtes-vous sûr de vouloir réhabiliter l'utilisateur ?",
  cannot_connect_anymore:
    "L'utilisateur ne pourra plus se connecter et donc gérer ses zones.",
  can_connect_again:
    "L'utilisateur pourra à nouveau se connecter et gérer ses zones.",
  email_will_be_sent: "Un e-mail lui sera envoyé.",

  // User Detail
  breadcrumb_user_detail_label: "Détail d'un utilisateur",
  user_detail_title: "Détail d'un utilisateur",
  delete_user_confirm: "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
  definitive_deletion: "La suppression d'un utilisateur est définitive.",
  resend_mail_confirm: "Êtes-vous sûr de vouloir renvoyer un mail à l'utilisateur ?",
  email_address_reminder: "Vous allez envoyer un email à ",
  correct_email_address: "Cette adresse est-elle correcte ?",

  // Password
  // NOSONAR
  breadcrumb_password_label: "Modification du mot de passe",
  // NOSONAR
  password_title: "Modification du mot de passe",
  password_oldPassword: "Mot de passe actuel",
  // NOSONAR
  password_newPassword: "Nouveau mot de passe",
  // NOSONAR
  password_confirmPassword: "Confirmation du nouveau mot de passe",

  // Profile
  breadcrumb_profile_label: "Mon profil",
  profile_title: "Mon profil : ",
  profile_lastname_label: "Nom : ",
  profile_firstname_label: "Prénom : ",
  profile_jobTitle_label: "Fonction : ",
  profile_company_label: "Société : ",
  profile_email_label: "Mail : "
};

export default Messages;
