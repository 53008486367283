import React, { useEffect, useState } from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import ListeBarreActions from "../../components/liste/ListeBarreActions";
import Shortcuts from "../../components/shortcuts/Shortcuts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faPlusCircle,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import CardLayout from "../../components/layouts/CardLayout";
import {
  STATUT_ZONE,
  USER_VALIDATION_MESSAGES
} from "../../constants/constant";
import { navigate } from "@reach/router";
import {
  TypeRegion,
  TypeSearchQueue,
  TypeUserValidationMessage,
  TypeZone
} from "../../constants/types";
import { useStore } from "effector-react";
import { filter, regions } from "../../state/store";
import moment from "moment";
import { DATE_LITTERAL_FORMAT } from "../../constants/dateFormat";
import { getQueryVariable, saveFile, usePrevious } from "../../utils/utils";
import { SortingRule } from "react-table-6";
import { clearCommentsAndAvis, clearZoneDetail } from "../../state/event";
import { ROUTE_NEW } from "../../constants/route";
import api from "../../network/api";
import { toast } from "react-toastify";
import PaginatedList from "../../components/liste/PaginatedList";

type Props = {
  intl: InjectedIntl;
  path: string;
};

let timer: NodeJS.Timeout;

function ZoneListe({ intl }: Props) {
  // States
  const [properties, setProperties] = useState({
    idListe: "zone",
    lienPrefix: "zone",
    queryFilterLabel: "Zone"
  });
  const [data, setData] = useState([] as TypeZone[]);
  const [selectedRegion, setSelectedRegion] = useState(0 as number);
  const [loading, setLoading] = useState(false as boolean);
  const [text, setText] = useState("" as string);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(0);
  const [searchQueue, setSearchQueue] = useState([] as TypeSearchQueue[]);
  const isConnected = sessionStorage && sessionStorage.token;

  // Stores
  const regionList = useStore(regions);
  const filterStore = useStore(filter);

  // Constants
  const previousText = usePrevious(text);
  const sort = { id: "modificationDate", desc: true } as SortingRule;

  // Effects
  useEffect(() => {
    clearZoneDetail();
    clearCommentsAndAvis();
    const message = getQueryVariable("message");
    if (message) {
      sendNotificationFromQueryParameters(message);
    }
  }, []);

  useEffect(() => {
    if (text != previousText && previousText !== undefined) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("pageSize : " + pageSize);
        const newQueue = [...searchQueue];
        newQueue.push({
          ...filterStore,
          region: regionList[selectedRegion],
          text,
          pageNo: page-1,
          pageSize,
          sort: sort
        });
        setSearchQueue(newQueue);
      }, 500);
    } else {
      console.log("pageSize : " + pageSize);
      const newQueue = [...searchQueue];
      newQueue.push({
        ...filterStore,
        region: regionList[selectedRegion],
        text,
        pageNo: page-1,
        pageSize,
        sort: sort
      });
      setSearchQueue(newQueue);
    }
  }, [filterStore, selectedRegion, text, sessionStorage.token]);

  useEffect(() => {
    if (searchQueue.length !== 0 && !loading) {
      const newQueue = [...searchQueue];
      const body = newQueue.shift();
      search(body);
      setSearchQueue(newQueue);
    }
  }, [page, pageSize, searchQueue, loading]);

  function sendNotificationFromQueryParameters(message: string) {
    const validationMessage =
      USER_VALIDATION_MESSAGES[message as TypeUserValidationMessage];
    if (validationMessage) {
      if (validationMessage.type === "success") {
        toast.success(
          USER_VALIDATION_MESSAGES[message as TypeUserValidationMessage].message
        );
      } else if (validationMessage.type === "error") {
        toast.error(
          USER_VALIDATION_MESSAGES[message as TypeUserValidationMessage].message
        );
      }
    } else {
      toast.error(message.replace(/\+/g, " "));
    }
  }

  const search = (body?: TypeSearchQueue) => {
    console.log(body);
    console.log(sort);
    setLoading(true);
    const url = "/api/zones/search";
    api
      .postJSON(url, JSON.stringify(body))
      .then(json => {
        console.log(json);
        setPages(json.totalPages);
        setData(json.content);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () => {
    const url = "/api/zones/export";
    const body = { ...filterStore, region: regionList[selectedRegion], text };
    api
      .postJSON(url, JSON.stringify(body))
      .then((blob: Blob | undefined) => {
        if (blob) {
          saveFile(blob, `zone_${moment().format("DDMMYYYYHHmmss")}.xlsx`);
        }
      })
      .catch(err => console.log(err));
  };

  const { formatMessage } = intl;

  const columns = [
    {
      Header: formatMessage({ id: "header_nom_zone_label" }),
      accessor: "name"
    },
    {
      Header: formatMessage({ id: "header_region_label" }),
      accessor: "regionName"
    },
    {
      Header: formatMessage({ id: "header_statut_label" }),
      id: "status",
      accessor: (props: TypeZone) => {
        const status = props.status;
        const statusZone = STATUT_ZONE[status];
        return statusZone ? (
          <div className={`overflow-ellipsis text-${statusZone.variant}`}>
            {statusZone.description}
          </div>
        ) : (
          ""
        );
      },
      maxWidth: 191
    },
    {
      Header: formatMessage({ id: "header_maj_label" }),
      id: "modificationDate",
      accessor: (props: TypeZone) =>
        moment(props.modificationDate)
          .utc()
          .format(DATE_LITTERAL_FORMAT),
      maxWidth: 140
    },
    {
      Header: formatMessage({ id: "header_auteur_label" }),
      accessor: "author"
    },
    {
      Header: formatMessage({ id: "header_fin_consultation_label" }),
      id: "endConsultation",
      accessor: (props: TypeZone) =>
        props.endConsultation &&
        moment(props.endConsultation)
          .utc()
          .format(DATE_LITTERAL_FORMAT),
      maxWidth: 190
    },
    {
      Header: formatMessage({ id: "header_stakeholders_mail_date_label" }),
      id: "stakeholdersMailDate",
      accessor: (props: TypeZone) =>
        props.stakeholdersMailDate &&
        moment(props.stakeholdersMailDate)
          .utc()
          .format(DATE_LITTERAL_FORMAT),
      maxWidth: 164
    },
    {
      Header: formatMessage({ id: "header_nb_comment_label" }),
      accessor: "nbComment",
      maxWidth: 170
    },
    {
      Header: formatMessage({ id: "header_nb_opinion_label" }),
      accessor: "nbOpinion",
      maxWidth: 100
    },
    {
      Header: "",
      id: "actions",
      minWidth: 80,
      maxWidth: 140,
      sortable: false,
      className: "actions",
      accessor: (props: TypeZone) => (
        <ListeBarreActions
          id={props.id}
          name={props.name}
          lienPrefix="zone"
          zoneStatus={props.status}
        />
      )
    }
  ];

  const columnsPublic = [
    {
      Header: formatMessage({ id: "header_nom_zone_label" }),
      accessor: "name"
    },
    {
      Header: formatMessage({ id: "header_region_label" }),
      accessor: "regionName"
    },
    {
      Header: formatMessage({ id: "header_statut_label" }),
      id: "status",
      accessor: (props: TypeZone) => {
        const status = props.status;
        const statusZone = STATUT_ZONE[status];
        return statusZone ? (
          <div className={`overflow-ellipsis text-${statusZone.variant}`}>
            {statusZone.description}
          </div>
        ) : (
          ""
        );
      }
    },
    {
      Header: formatMessage({ id: "header_fin_consultation_label" }),
      id: "endConsultation",
      accessor: (props: TypeZone) =>
        props.endConsultation &&
        moment(props.endConsultation)
          .utc()
          .format(DATE_LITTERAL_FORMAT)
    },
    {
      Header: formatMessage({ id: "header_nb_comment_label" }),
      accessor: "nbComment",
      maxWidth: 200
    },
    {
      Header: formatMessage({ id: "header_nb_opinion_label" }),
      accessor: "nbOpinion",
      maxWidth: 200
    }
  ];

  return (
    <>
      <div className="mb-3">
        <CardLayout
          bodyClassName="p-5"
          headerClassName="d-flex flex-row flex-wrap align-content-center justify-content-between"
          idTitle="zone_liste_presentation_title"
        >
          <p>
            Le décret «{" "}
            <a
              href={
                "https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000038702420&categorieLien=id"
              }
              target="_blank"
            >
              droit à l’injection
            </a>{" "}
            » et sa mise en application dans la{" "}
            <a
              href={
                "https://www.cre.fr/Documents/Deliberations/Decision/mecanismes-encadrant-l-insertion-du-biomethane-dans-les-reseaux-de-gaz"
              }
              target="_blank"
            >
              délibération N°2019-242
            </a>{" "}
            de la Commission de Régulation de l’Energie (CRE) encadrent
            l’insertion du biométhane dans les réseaux de gaz. Les opérateurs de
            réseaux doivent se concerter pour définir le raccordement optimal
            des projets d’injection d’une zone en minimisant les coûts
            d’adaptation des réseaux pour la collectivité. Le zonage de
            raccordement doit être ensuite soumis à consultation notamment pour
            disposer d’informations plus précises sur le volume de biométhane
            mobilisable sur le territoire. Finalement, le zonage de raccordement
            est soumis à la CRE pour validation.
          </p>
          <p>
            Une fois par an, les opérateurs de réseau publient une carte synthétisant tous ces zonages de raccordement. Cette carte est disponible en suivant {" "}
            <a
              href={
                "https://grtgazfrance.maps.arcgis.com/apps/webappviewer/index.html?id=11cf56f60e3e47ebb13ce6140e939703"
              }
              target="_blank"
            >
              ce lien
            </a>
            .
          </p>
          <p>
            La présente plateforme a pour vocation de regrouper les
            consultations sur les zonage de raccordement biométhane. Chaque
            consultation est ouverte pour une durée de 3 semaines calendaires.
          </p>
        </CardLayout>
      </div>
      <CardLayout
        headerClassName="d-flex flex-row flex-wrap align-content-center justify-content-between"
        idTitle="zone_liste_header_title"
        nextTitleComponent={
          isConnected ? (
            <ButtonIconBackward
              icon={faPlusCircle}
              message="zone_liste_button_new_label"
              variant="primary"
              onClick={() => navigate(ROUTE_NEW)}
            />
          ) : null
        }
      >
        <div className="px-lg-4 my-3">
          <Shortcuts disabled={loading} />
        </div>
        <div className="d-flex flex-row align-items-stretch flex-wrap px-4">
          <div className="m-2">
            <Dropdown onSelect={(eventKey: any) => setSelectedRegion(eventKey)}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <FormattedMessage id="zone_liste_dropdown_label" />
                {regionList[selectedRegion] && regionList[selectedRegion].name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {regionList.map((region: TypeRegion) => (
                  <Dropdown.Item
                    key={region.id}
                    eventKey={region.id.toString()}
                  >
                    {region.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="flex-grow-1 m-2">
            <InputGroup>
              <FormControl
                type="text"
                name="search"
                placeholder={formatMessage({
                  id: "zone_liste_search_placeholder"
                })}
                onChange={(e: any) => setText(e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <PaginatedList
          columns={isConnected ? columns : columnsPublic}
          sortingAttributes={[
            "regionName",
            "name",
            "status",
            "modificationDate",
            "author",
            "endConsultation",
            "nbComment",
            "nbOpinion"
          ]}
          datas={data}
          properties={properties}
          loading={loading}
          initialSortingRule={sort}
          sendPage={(page) => {
            setPage(page);
            console.log("pageSize : " + pageSize);
            const newQueue = [...searchQueue];
            newQueue.push({
              ...filterStore,
              region: regionList[selectedRegion],
              text,
              pageNo: page-1,
              pageSize,
              sort: sort
            });
            setSearchQueue(newQueue);
          }}
          sendPageSize={(size) => {
            console.log("setPageSize to " + size);
            setPageSize(size);
            console.log("pageSize : " + pageSize);
            const newQueue = [...searchQueue];
            newQueue.push({
              ...filterStore,
              region: regionList[selectedRegion],
              text,
              pageNo: page-1,
              pageSize: size,
              sort: sort
            });
            setSearchQueue(newQueue);
          }}
          sendSort={(sort) => {
            console.log("setSort to " + sort);
            const newQueue = [...searchQueue];
            newQueue.push({
              ...filterStore,
              region: regionList[selectedRegion],
              text,
              pageNo: page-1,
              pageSize,
              sort: sort
            });
            setSearchQueue(newQueue);
          }}
          pages={pages}
        />
        {isConnected && (
          <div className="d-flex justify-content-center mb-4">
            <ButtonIconBackward
              icon={faFileExcel}
              message="export_excel_button_label"
              className="btn-tertiary"
              onClick={exportData}
            />
          </div>
        )}
      </CardLayout>
    </>
  );
}

export default injectIntl(ZoneListe);
