import React from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import Form from "react-bootstrap/Form";
import { Button, ButtonGroup } from "react-bootstrap";
import { TypeOption, TypeTooltip } from "../../constants/types";
import Tooltip from "../button/Tooltip";

type Props = {
  name?: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  onChange: (e: any) => void;
  enumOptions: TypeOption[];
  value?: TypeOption;
  tooltip?: TypeTooltip;
  error?: string;
};

const RadioButtonInput = ({
  name,
  idLabel,
  valuesLabel,
  value,
  enumOptions,
  onChange,
  tooltip,
  error
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
          {tooltip && <Tooltip {...tooltip} />}
        </span>
      </Form.Label>
      <ButtonGroup aria-label={name} className="radio-button-group">
        {enumOptions.map(option => (
          <Button
            key={option.id}
            onClick={() => onChange({ target: { name, value: option } })}
            className={value && option.id === value.id ? "active" : ""}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
      <div className="invalid-feedback d-block mb-5">{error}</div>
    </Form.Group>
  </>
);

export default RadioButtonInput;
