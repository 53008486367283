import React, { useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import {faFileExcel, faSearch, faUser} from "@fortawesome/free-solid-svg-icons";
import CardLayout from "../../components/layouts/CardLayout";
import { FormControl, InputGroup } from "react-bootstrap";
import {
  TypeBreadcrumb,
  TypeProperty,
  TypeRole,
  TypeSearchUserResource,
  TypeUser
} from "../../constants/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Liste from "../../components/liste/Liste";
import { SortingRule } from "react-table-6";
import api from "../../network/api";
import {saveFile, usePrevious} from "../../utils/utils";
import CenteredModal from "../../components/modal/CenteredModal";
import { ROLES } from "../../constants/constant";
import { toast } from "react-toastify";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import { ROUTE_SPONSORSHIP } from "../../constants/route";
import { navigate } from "@reach/router";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import {clearUserDetail} from "../../state/event";
import moment from "moment";

interface Props {
  intl: InjectedIntl;
  path: string;
}

let timer: NodeJS.Timeout;

function UserList({ intl }: Props) {
  const { formatMessage } = intl;

  // States
  const [searchText, setSearchText] = useState("");
  const previousText = usePrevious(searchText);
  const [users, setUsers] = useState([] as TypeUser[]);
  const [currentActionUser, setCurrentActionUser] = useState<TypeUser>();
  const [loading, setLoading] = useState(false as boolean);
  const [showModalConfirmSuspend, setShowModalConfirmSuspend] = useState(false);
  const [
    showModalConfirmRehabilitate,
    setShowModalConfirmRehabilitate
  ] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);

  const properties = {
    idListe: "users",
    lienPrefix: "users",
    queryFilterLabel: "Users",
   // lienCallback: () => null
  } as TypeProperty;

  useEffect(() => {
    clearUserDetail();
    const { formatMessage } = intl;
    const newBreadcrumb = [
      {
        id: "fil-ariane-saisie",
        label: formatMessage({
          id: "breadcrumb_user_list_label"
        })
      }
    ];
    setBreadcrumb(newBreadcrumb);

    const url = "/api/users";
    api.getJSON(url).then(json => {
      setUserRole(json.role);
    });
  }, []);

  // Effects
  useEffect(() => {
    if (!loading) {
      if (searchText != previousText) {
        clearTimeout(timer);
        timer = setTimeout(() => search(), 500);
      } else {
        search();
      }
    }
  }, [searchText]);

  const search = () => {
    setLoading(true);
    const url = "/api/users/search/";
    const body: TypeSearchUserResource = { text: searchText };
    api
      .postJSON(url, JSON.stringify(body))
      .then(json => {
        setUsers(json);
      })
      .finally(() => setLoading(false));
  };

  const exportData = () => {
    const body: TypeSearchUserResource = { text: searchText };
    const url = "/api/users/export";
    api
        .postJSON(url, JSON.stringify(body))
        .then((blob: Blob | undefined) => {
          if (blob) {
            saveFile(blob, `user_${moment().format("DDMMYYYYHHmmss")}.xlsx`);
          }
        })
        .catch(err => console.log(err));
  };

  function formatBooleanMessage(value: boolean) {
    return value ? formatMessage({ id: "yes" }) : formatMessage({ id: "no" });
  }

  function askSuspendUser(props: TypeUser) {
    setCurrentActionUser(props);
    if (props.inactive) {
      setShowModalConfirmRehabilitate(true);
    } else {
      setShowModalConfirmSuspend(true);
    }
  }

  function handleSuspendUser() {
    if (currentActionUser && currentActionUser.id) {
      postAndSearch("/api/users/" + currentActionUser.id + "/suspend");
    }
  }

  function handleRehabilitateUser() {
    if (currentActionUser && currentActionUser.id) {
      postAndSearch("/api/users/" + currentActionUser.id + "/rehabilitate");
    }
  }

  function postAndSearch(url: string) {
    setLoading(true);
    api
      .postJSON(url, "")
      .then(() => toast.success("L'utilisateur a été prévenu de l'action"))
      .finally(() => {
        search();
        setCurrentActionUser(undefined);
        setLoading(false);
      });
  }

  const columns = [
    {
      Header: formatMessage({ id: "header_firstname_user_list" }),
      accessor: "firstname"
    },
    {
      Header: formatMessage({ id: "header_lastname_user_list" }),
      accessor: "lastname"
    },
    {
      Header: formatMessage({ id: "header_email_user_list" }),
      accessor: "email"
    },
    {
      Header: formatMessage({ id: "header_role_user_list" }),
      id: "role",
      accessor: (props: TypeUser) =>
        props.role ? ROLES[props.role as TypeRole]?.label : ""
    },
    {
      Header: formatMessage({ id: "header_jobTitle_user_list" }),
      accessor: "jobTitle"
    },
    {
      Header: formatMessage({ id: "header_company_user_list" }),
      accessor: "company"
    },
    {
      Header: formatMessage({ id: "header_suspended_user_list" }),
      id: "inactive",
      accessor: (props: TypeUser) => formatBooleanMessage(props.inactive)
    },
    {
      Header: formatMessage({ id: "header_validated_user_list" }),
      id: "validated",
      accessor: (props: TypeUser) => formatBooleanMessage(props.validated)
    },
    {
      Header: "Actions",
      id: "actions",
      minWidth: 80,
      sortable: false,
      className: "actions",
      accessor: (props: TypeUser) =>
        userRole === ROLES.ROLE_SUPERADMIN.role &&
        props.role !== ROLES.ROLE_SUPERADMIN.role && (
          <span className="action-liste" onClick={() => askSuspendUser(props)}>
            {props.inactive ? "Réhabiliter" : "Suspendre"}
          </span>
        )
    }
  ];

  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout
        headerClassName="d-flex flex-row flex-wrap align-content-center justify-content-between"
        idTitle="user_list_header_title"
        nextTitleComponent={
          <div className="buttons">
            <ButtonIconBackward
              icon={faUser}
              message="sponsorship_button_label"
              className="m-2"
              variant="primary"
              onClick={() => navigate(ROUTE_SPONSORSHIP)}
            />
          </div>
        }
      >
        <div className="d-flex flex-row align-items-stretch flex-wrap px-4">
          <div className="flex-grow-1 m-2">
            <InputGroup>
              <FormControl
                type="text"
                name="search"
                placeholder={formatMessage({
                  id: "user_list_search_placeholder"
                })}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <Liste
          columns={columns}
          sortingAttributes={[
            "lastname",
            "firstname",
            "email",
            "role",
            "jobTitle",
            "company",
            "inactive"
          ]}
          showPaginationBottom={true}
          datas={users}
          properties={properties}
          loading={loading}
          initialSortingRules={[{ id: "email", desc: true }] as SortingRule[]}
        />
        <div className="d-flex justify-content-center mb-4">
          <ButtonIconBackward
              icon={faFileExcel}
              message="export_excel_button_label"
              className="btn-tertiary"
              onClick={exportData}
          />
        </div>
      </CardLayout>
      <CenteredModal
        show={showModalConfirmSuspend}
        idTitle="suspend_confirm"
        onConfirm={() => {
          setShowModalConfirmSuspend(false);
          handleSuspendUser();
        }}
        onCancel={() => {
          setShowModalConfirmSuspend(false);
        }}
      >
        <p>{formatMessage({ id: "cannot_connect_anymore" })}</p>
        <p>{formatMessage({ id: "email_will_be_sent" })}</p>
      </CenteredModal>
      <CenteredModal
        show={showModalConfirmRehabilitate}
        idTitle="rehabilitate_confirm"
        onConfirm={() => {
          setShowModalConfirmRehabilitate(false);
          handleRehabilitateUser();
        }}
        onCancel={() => {
          setShowModalConfirmRehabilitate(false);
        }}
      >
        <p>{formatMessage({ id: "can_connect_again" })}</p>
        <p>{formatMessage({ id: "email_will_be_sent" })}</p>
      </CenteredModal>
    </>
  );
}

export default injectIntl(UserList);
