import React from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import Form from "react-bootstrap/Form";
import { TypeOption } from "../../constants/types";
import Select, { OptionsType, Theme, ValueType } from "react-select";

type Props = {
  name?: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  onChange: (e: any) => void;
  enumOptions: TypeOption[];
  placeholder?: string;
  error?: string;
  value?: TypeOption;
};

const SelectInput = ({
  name,
  idLabel,
  valuesLabel,
  onChange,
  enumOptions,
  placeholder = "Sélectionner une option",
  error,
  value
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <Select
        placeholder={placeholder}
        name={name}
        onChange={(e: ValueType<TypeOption>) =>
          onChange({ target: { name: name, value: e as TypeOption } })
        }
        value={value}
        theme={
          {
            spacing: {
              baseUnit: 5,
              controlHeight: 44,
              menuGutter: 10
            }
          } as Theme
        }
        isOptionSelected={(
          option: TypeOption,
          list: OptionsType<TypeOption>
        ): boolean => !!list.find(item => item.id === option.id)}
        options={enumOptions.map(option => ({
          id: option.id,
          label: option.label
        }))}
        className={!!error ? "invalid select" : ""}
      />
      <div className="invalid-feedback d-block">{error}</div>
    </Form.Group>
  </>
);

export default SelectInput;
