import React, { ReactNode } from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import ButtonIconForward from "../button/ButtonIconForward";
import ButtonIconBackward from "../button/ButtonIconBackward";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Badge } from "react-bootstrap";

type Props = {
  headerClassName?: string;
  title?: string | null;
  idTitle?: string | null;
  badge?: string | null;
  variantBadge?: any;
  date?: string | null;
  valuesTitle?: { [key: string]: MessageValue | JSX.Element };
  nextTitleComponent?: ReactNode;
  bodyClassName?: string;
  children: ReactNode;
};

const CardLayout = ({
  children,
  headerClassName = "",
  title = null,
  idTitle = null,
  badge = null,
  variantBadge = "primary",
  date = null,
  valuesTitle = {},
  nextTitleComponent = null,
  bodyClassName = ""
}: Props) => (
  <>
    <div className="content card shadow">
      <div className={`content-header ${headerClassName}`}>
        <div>
          <h2 className="content-title d-flex align-items-center">
            {idTitle && <FormattedMessage id={idTitle} values={valuesTitle} />}
            {title}
            {badge && (
              <Badge variant={variantBadge} className="ml-4">
                {badge}
              </Badge>
            )}
          </h2>
          {date && (
            <span className="subtitle text-grey">
              <FormattedMessage id="detail_zone_consultation_end_date_label" />
              {date}
            </span>
          )}
        </div>
        {nextTitleComponent}
      </div>
      <div className={`content-body ${bodyClassName}`}>{children}</div>
    </div>
  </>
);

export default CardLayout;
