import React from "react";
import UnorderedListItem from "./UnorderedListItem";
import { TypeListItem } from "../../constants/types";
import parse from "html-react-parser";

type Props = {
  className?: string;
  items: TypeListItem[];
};

const UnorderedList = ({ className = "", items = [] }: Props) => (
  <>
    <ul className={className}>
      {items.map(
        (item, index) =>
          item.content && (
            <UnorderedListItem
              key={index}
              title={item.title}
              content={
                item.unit
                  ? parse(
                      `${item.content} <span style="color: #0053a1" >${item.unit}</span>`
                    )
                  : parse(item.content.toString())
              }
              total={item.total}
              tooltip={item.tooltip}
            />
          )
      )}
    </ul>
  </>
);

export default UnorderedList;
