import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import React from "react";
import { TypeButtonVariant } from "../../constants/bootstrapTypes";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { saveFile } from "../../utils/utils";
import api from "../../network/api";

interface Props {
  filename: string;
  urlDownload: string;
  className?: string;
  disabled?: boolean;
  variant?: TypeButtonVariant;
  hidden?: boolean;
}
export function ButtonDownloadAndSaveFile({
  filename,
  urlDownload,
  className = "btn-file",
  disabled = false,
  variant,
  hidden
}: Props) {
  function onClick() {
    api.getJSON(urlDownload).then(blob => saveFile(blob, filename));
  }

  return (
    <Button
      type={"button"}
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      hidden={hidden}
    >
      <span className="mr-2">{filename}</span>
      <span className="icon">
        <FontAwesomeIcon icon={faPaperclip} />
      </span>
    </Button>
  );
}
