import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from "prop-types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TypeButtonType } from "../../constants/types";
import Button from "react-bootstrap/Button";
import { TypeButtonVariant } from "../../constants/bootstrapTypes";

type Props = {
  icon?: IconProp;
  onClick: (e: MouseEvent | any) => void;
  className: string;
  type: TypeButtonType;
  message: string;
  disabled: boolean;
  variant?: TypeButtonVariant;
  hidden: boolean;
};

const ButtonIconForward = ({
  icon,
  onClick,
  className,
  type,
  message,
  disabled,
  variant,
  hidden
}: Props) => (
  <Button
    type={type}
    className={className}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    hidden={hidden}
  >
    <span className="mr-2">
      <FormattedMessage id={message} />
    </span>
    {icon && (
      <span className="icon">
        <FontAwesomeIcon icon={icon} />
      </span>
    )}
  </Button>
);

ButtonIconForward.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  hidden: PropTypes.bool
};

ButtonIconForward.defaultProps = {
  onClick: null,
  className: "",
  type: "submit",
  message: "Soumettre",
  disabled: null,
  variant: "primary",
  hidden: false
};

export default ButtonIconForward;
