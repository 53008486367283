import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

type Props = {
  name: string;
  idLabel: string;
  valuesLabel?: any;
  value?: string;
  onChange: (e: any) => void;
  placeholder?: string;
  error?: string;
};

const RichText = ({
  name,
  idLabel,
  valuesLabel,
  value = "",
  onChange,
  placeholder,
  error
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <ReactQuill
        modules={{ toolbar: [["bold", "italic", "underline", "link"]] }}
        formats={["bold", "italic", "underline", "link"]}
        className={`richText-input ${!!error ? "invalid" : ""}`}
        value={value}
        onChange={(content: string) =>
          onChange({
            target: { name, value: content === "<p><br></p>" ? "" : content }
          })
        }
        placeholder={placeholder}
      />
      <div className="invalid-feedback d-block">{error}</div>
    </Form.Group>
  </>
);

export default RichText;
