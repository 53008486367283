import React from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage } from "react-intl";
import { navigate } from "@reach/router";
import { ROUTE_LEGAL_MENTIONS } from "../constants/route";

function Footer() {
  const footerList = [
    {
      title: "legal_mentions_title",
      url: ROUTE_LEGAL_MENTIONS
    }
  ];
  return (
    <>
      <div className="footer">
        <Container>
          <div className="copyright d-flex align-items-center justify-content-center">
            {footerList.map(footer => (
              <a
                key={footer.title}
                className="font-primary"
                href=""
                onClick={event => {
                  event.preventDefault();
                  navigate(footer.url);
                }}
              >
                <FormattedMessage id={footer.title} />
              </a>
            ))}
          </div>
          <div className="copyright d-flex align-items-center justify-content-center mt-3">
            <FormattedMessage id="copyright" />
          </div>
        </Container>
      </div>
    </>
  );
}

export default Footer;
