import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TypeLien } from "../../constants/types";
import { Link } from "@reach/router";
import { getIconByType } from "../../utils/utils";

function computeLienUrl(lien: TypeLien, id: number) {
  return lien.url.replace("{id}", id.toString());
}

type Props = {
  id: number;
  type: "details" | "delete" | "edit" | "export" | "stats";
  lien: {
    url: string;
  };
};

function ListeAction({ id, type, lien }: Props) {
  const lienUrl = computeLienUrl(lien, id) || "";
  const lienId = `${type}-${id}`;

  const icon = getIconByType(type);

  return (
    <Link className="action-liste" id={lienId} key={lienId} to={lienUrl}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  );
}

export default ListeAction;
