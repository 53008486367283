import React from "react";
import { Router } from "@reach/router";
import Sponsorship from "./Sponsorship";
import {
    PATH_PASSWORD,
    PATH_PROFILE,
    PATH_SPONSORSHIP, PATH_USER_DETAIL,
    PATH_USER_LIST
} from "../../constants/route";
import UserList from "./UserList";
import Password from "./Password";
import Profile from "./Profile";
import UserDetail from "./UserDetail";

interface Props {
  path: string;
}

function AdminRoute({ path }: Props) {
  return (
    <Router>
      <Sponsorship path={PATH_SPONSORSHIP} />
      <UserList path={PATH_USER_LIST} />
      <Profile path={PATH_PROFILE} />
      <Password path={PATH_PASSWORD} />
      <UserDetail path= {`${PATH_USER_DETAIL}/:userId`} />
    </Router>
  );
}

export default AdminRoute;
