import { createStore } from "effector";
import {
  FilterKeys,
  TypeAuthentication,
  TypeAvis,
  TypeComment,
  TypeFilter,
  TypeRegion,
  TypeUser, TypeUserDetail,
  TypeZoneDetail
} from "../constants/types";
import {
  deleteAvis,
  deleteComment,
  getAvisFromZone,
  getCommentsFromZone,
  getIsTokenValid,
  getUser, getUserDetail,
  getZoneDetail,
  postAvis,
  postComment,
  postCommentWithFile,
  setFilter,
  setIsTokenValid
} from "./effect";
import {
  FILTER,
  INITIAL_AVIS_STORE,
  INITIAL_COMMENT_STORE
} from "../constants/constant";
import {
  clearCommentsAndAvis, clearUserDetail,
  clearZoneDetail,
  tokenFromSession
} from "./event";

export const authentication = createStore({
  token: "",
  email: "",
  loading: false
} as TypeAuthentication);
authentication.on(tokenFromSession, (): TypeAuthentication | void => {
  return {
    token: sessionStorage.getItem("token"),
    email: sessionStorage.getItem("email"),
    loading: false
  };
});

export const regions = createStore([] as TypeRegion[]);

export const filter = createStore({
  mine: false,
  all: true,
  draft: false,
  consultation: false,
  consultationDone: false,
  deleted: false
} as TypeFilter);

filter.on(
  setFilter.done,
  (state: TypeFilter, payload: any): TypeFilter => {
    const key = payload.params as FilterKeys;
    if (key === FILTER.MINE || key === FILTER.ALL) {
      const oppositeKey = key === FILTER.MINE ? FILTER.ALL : FILTER.MINE;
      return {
        ...state,
        [key]: !state[key],
        [oppositeKey]: !state[oppositeKey]
      };
    } else {
      return { ...state, [key]: !state[key] };
    }
  }
);

export const comments = createStore(INITIAL_COMMENT_STORE);
comments.on(getCommentsFromZone.done, (oldState, payload) => payload.result);
comments.on(postComment.done, addNewComment);
comments.on(postCommentWithFile.done, addNewComment);
comments.on(deleteComment.done, deleteCommentStore);
comments.on(clearCommentsAndAvis, () => INITIAL_COMMENT_STORE);

function addNewComment(oldState: any, payload: any) {
  if (sessionStorage && sessionStorage.token) {
    return {
      count: oldState.count + 1,
      comments: [...oldState.comments, payload.result]
    };
  } else {
    return {
      count: oldState.count + 1,
      comments: oldState.comments
    };
  }
}
function deleteCommentStore(oldState: any, payload: any) {
  const newState = {
    count: oldState.count - 1,
    comments: oldState.comments.filter(
      (item: TypeComment) => item.id !== payload.params
    )
  };
  return newState;
}

export const avis = createStore(INITIAL_AVIS_STORE);
avis.on(getAvisFromZone.done, (oldState, payload) => payload.result);
avis.on(postAvis.done, addNewAvis);
avis.on(deleteAvis.done, deleteAvisStore);
avis.on(clearCommentsAndAvis, () => INITIAL_AVIS_STORE);

function addNewAvis(oldState: any, payload: any) {
  if (sessionStorage && sessionStorage.token) {
    return {
      count: oldState.count + 1,
      avis: [...oldState.avis, payload.result]
    };
  } else {
    return {
      count: oldState.count + 1,
      avis: oldState.avis
    };
  }
}
function deleteAvisStore(oldState: any, payload: any) {
  return {
    count: oldState.count - 1,
    avis: oldState.avis.filter((item: TypeAvis) => item.id !== payload.params)
  };
}

export const zoneStore = createStore(null as TypeZoneDetail | null);
zoneStore.on(getZoneDetail.done, (oldState, payload) => payload.result);
zoneStore.on(clearZoneDetail, () => null);

// export const userStore = createStore(null as TypeZoneDetail | null);
// userStore.on(getUserDetail.done, (oldState, payload) => payload.result);
// userStore.on(clearZoneDetail, () => null);

export const isTokenValidStore = createStore(false as boolean);
isTokenValidStore.on(
  getIsTokenValid.done,
  (oldState, payload) => payload.result
);
isTokenValidStore.on(
  setIsTokenValid.done,
  (oldState, payload) => payload.result
);

export const userStore = createStore(null as TypeUser | null);
userStore.on(getUser.done, (oldState, payload) => payload.result);

export const userDetailStore = createStore(null as TypeUserDetail | null);
userDetailStore.on(getUserDetail.done, (oldState, payload) => payload.result);
userDetailStore.on(clearUserDetail, () => null);