import {
  faEdit,
  faEye,
  faFilePdf,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useRef } from "react";
import api from "../network/api";
import { disconnect } from "../state/event";
import { setFilter } from "../state/effect";
import { FILTER } from "../constants/constant";
import { navigate } from "@reach/router";
import { ROUTE_HOME } from "../constants/route";

export const getIconByType = (type: string): IconProp => {
  switch (type) {
    case "export":
      return faFilePdf;
    case "edit":
      return faEdit;
    case "stats":
      return faList;
    default:
      return faEye;
  }
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const arrayBufferToBase64 = (buffer: Uint8Array): string => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const length = bytes.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function base64ToArrayBuffer(base64: any) {
  return new Uint8Array(base64);
}

export function saveFile(content: File | Blob, fileName: string) {
  const a = document.createElement("a");
  let file;
  if (content instanceof Blob) {
    file = content;
  } else {
    file = new Blob([base64ToArrayBuffer(content)]);
  }
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
  if (a.parentNode) {
    a.parentNode.removeChild(a);
  }
}

export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
};

export const exportData = (zoneId?: number, name?: string) => {
  const url = "/api/zones/" + zoneId + "/report/zip";
  api.getJSON(url).then((blob: Blob | undefined) => {
    if (blob) {
      saveFile(blob, `rapport_zone_${name}.zip`);
    }
  });
};
