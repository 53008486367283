import { Spinner } from "react-bootstrap";
import React from "react";

function Loader() {
  return (
    <div className="w-100 d-flex justify-content-center h-100 center">
      <Spinner animation="border" />
    </div>
  );
}

export default Loader;
