import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  icon: IconProp;
  onClick?: (e: MouseEvent | any) => void;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  isLoading?: boolean;
};

const ButtonIcon = ({
  icon,
  onClick,
  className,
  disabled,
  hidden,
  isLoading
}: Props) => (
  <Button
    type="button"
    className={`btn-icon ${className}`}
    onClick={onClick}
    disabled={disabled}
    hidden={hidden}
  >
    {isLoading ? (
      <Spinner animation="border" size="sm" />
    ) : (
      <FontAwesomeIcon icon={icon} />
    )}
  </Button>
);

export default ButtonIcon;
