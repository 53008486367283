import React from "react";
import ZoneListe from "./ZoneListe";
import { Router } from "@reach/router";
import ZoneDetail from "./ZoneDetail";
import ZoneSaisie from "./ZoneSaisie";
import ZoneStats from "./ZoneStats";

function ZoneRoute() {
  return (
    <Router>
      {sessionStorage && !!sessionStorage.token && (
        <>
          <ZoneSaisie path="new" />
          <ZoneSaisie path=":zoneId/edit" />
          <ZoneStats path=":zoneId/stats" />
        </>
      )}
      <ZoneDetail path=":zoneId" />
      <ZoneListe path="/" />
    </Router>
  );
}

export default ZoneRoute;
