import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";
import { TypeTooltip } from "../../constants/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function Tooltip({
  placement,
  tooltipTitle,
  tooltipContent,
  trigger = "hover",
  afterText = ""
}: TypeTooltip) {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={
        <Popover id={`popover-positioned-${placement}`}>
          <Popover.Title as="h3">{tooltipTitle}</Popover.Title>
          <Popover.Content>{tooltipContent}</Popover.Content>
        </Popover>
      }
    >
      <span>
        <FontAwesomeIcon icon={faInfoCircle} />
        {afterText}
      </span>
    </OverlayTrigger>
  );
}

export default Tooltip;
