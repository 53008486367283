import React, { Fragment, useEffect, useState } from "react";
import {
  faHourglass,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faMap,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import Shortcut from "./Shortcut";
import { FilterKeys, TypeCount } from "../../constants/types";
import api from "../../network/api";
import { FILTER, ROLES } from "../../constants/constant";
import { useStore } from "effector-react";
import { filter, userStore } from "../../state/store";
import { setFilter } from "../../state/effect";

type Props = {
  disabled: boolean;
};

function Shortcuts({ disabled }: Props) {
  const [count, setCount] = useState({
    mine: 0,
    all: 0,
    draft: 0,
    consultation: 0,
    consultationDone: 0,
    deleted: 0
  } as TypeCount);
  const [loading, setLoading] = useState(false as boolean);
  const [countQueue, setCountQueue] = useState([] as boolean[]);
  const filterStore = useStore(filter);
  const user = useStore(userStore);
  const isConnected = sessionStorage && sessionStorage.token;

  useEffect(() => {
    const newQueue = [...countQueue];
    newQueue.push(filterStore.mine);
    setCountQueue(newQueue);
  }, [filterStore.mine, sessionStorage.token]);

  useEffect(() => {
    if (!loading && countQueue.length !== 0) {
      setLoading(true);
      const newQueue = [...countQueue];
      const mine = newQueue.shift();
      setCountQueue(newQueue);
      api
        .getJSON("/api/zones/count/" + mine)
        .then(json => setCount(json))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, countQueue]);

  useEffect(() => {
    if (isConnected && !filterStore.mine) {
      setFilter(FILTER.MINE);
    }
  }, []);

  const onClick = (clickedFilter: FilterKeys) => {
    setFilter(clickedFilter);
  };
  return (
    <Fragment>
      <div
        className={`shortcuts d-flex flex-row flex-wrap align-items-stretch justify-content-between pt-1 pb-4 mb-1 ${
          isConnected ? "connected multiple-5" : "disconnected multiple-2"
        }`}
      >
        {isConnected && (
          <>
            <Shortcut
              icon={faMapMarkedAlt}
              description="Mes zones"
              count={count.mine}
              onClick={() => onClick(FILTER.MINE)}
              active={filterStore.mine}
              disabled={disabled}
            />
            <Shortcut
              icon={faMap}
              description="Toutes les zones"
              count={count.all}
              onClick={() => onClick(FILTER.ALL)}
              active={filterStore.all}
              disabled={disabled}
            />
            <Shortcut
              icon={faHourglassStart}
              description="Zones en cours de création"
              count={count.draft}
              onClick={() => onClick(FILTER.DRAFT)}
              active={filterStore.draft}
              disabled={disabled}
            />
          </>
        )}
        <Shortcut
          icon={faHourglassHalf}
          description="Zones en cours de consultation"
          count={count.consultation}
          onClick={() => onClick(FILTER.CONSULTATION)}
          active={filterStore.consultation}
          disabled={disabled}
        />
        <Shortcut
          icon={faHourglassEnd}
          description="Zones avec consultation terminée"
          count={count.consultationDone}
          onClick={() => onClick(FILTER.CONSULTATION_DONE)}
          active={filterStore.consultationDone}
          disabled={disabled}
        />
        {isConnected && user?.role === ROLES.ROLE_SUPERADMIN.role && (
          <Shortcut
            icon={faHourglass}
            description="Zones supprimées"
            count={count.deleted}
            onClick={() => onClick(FILTER.DELETED)}
            active={filterStore.deleted}
            disabled={disabled}
          />
        )}
      </div>
    </Fragment>
  );
}

export default Shortcuts;
