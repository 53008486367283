import { toast } from "react-toastify";
import { disconnect } from "../state/event";

const api = {
  getJSON: (url: string) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      }
    })
      .then(handleResult)
      .catch(handleError);
  },
  postJSON: (url: string, JSONbody: string) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSONbody
    })
      .then(handleResult)
      .catch(handleError);
  },
  putJSON: (url: string, JSONbody: string) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSONbody
    })
      .then(handleResult)
      .catch(handleError);
  },
  postFormData: (url: string, formData: FormData, id?: string | null) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: authorization,
        "x-entity-id": id ? id.toString() : ""
      },
      body: formData
    })
      .then(handleResult)
      .catch(handleError);
  },
  delete: (url: string) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      }
    })
      .then(handleResult)
      .catch(handleError);
  },
  putResetPasswordAndSendMail: (url: string) => {
    const token = sessionStorage.getItem("token");
    const authorization = !!token ? "Bearer " + token : "";
    return fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      }
    })
        .then(handleResult)
        .catch(handleError);
  }
};

const handleError = (error: Promise<any> | string) => {
  if ("string" === typeof error) {
    toast.error(error);
  } else if (typeof error !== "function") {
    error.then((errorBody: Error) => {
      if (errorBody.message) {
        toast.error(errorBody.message);
      } else {
        toast.error("Une erreur est survenue");
      }
    });
  } else {
    toast.error(error);
  }
  throw error;
};

const handleResult = (res: Response) => {
  const contentType = res.headers.get("content-type");
  if (res.status === 200 || res.status === 201) {
    if (contentType === "application/json") {
      return res.json();
    } else if (
      contentType === "application/pdf" ||
      contentType === "application/zip" ||
      contentType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      contentType === "application/octet-stream"
    ) {
      return res.blob();
    }
  } else if (res.status === 409) {
    disconnect();
    window.location.reload();
  } else {
    throw res.json();
  }
};

export default api;
