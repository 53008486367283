import React from "react";
import CardLayout from "../components/layouts/CardLayout";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { InjectedIntl, injectIntl } from "react-intl";

type Props = {
  intl: InjectedIntl;
};

function LegalMentions({ intl }: Props) {
  const { formatMessage } = intl;
  const breadcrumb = [
    {
      id: "legal-mentions",
      label: formatMessage({ id: "legal_mentions_title" })
    }
  ];

  const SITE_LINK = (
    <a
      href="https://www.consultation-zonage-de-raccordement-biomethane.fr"
      target="_blank"
    >
      www.consultation-zonage-de-raccordement-biomethane.fr
    </a>
  );
  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <CardLayout
        title={formatMessage({ id: "legal_mentions_page_title" })}
        bodyClassName="px-4 pb-4 details-container legal-mentions"
      >
        <section>
          <h3 className="text-primary">Editeur </h3>
          <p>
            Directeur de Publication du site : Jérôme Chambin
            <br/><br/>Siège social : 6, rue Condorcet 75 009 PARIS Société anonyme au capital de 1 800 745 000 euros immatriculée auprès du registre de commerce et des sociétés de Paris sous le numéro 444 786 511.
            <br/><br/>Identifiant TVA : FR 94 444 786 511
            <br/><br/>SIRET : 444 786 511 00022
            <br/><br/>APE : 3522 Z
          </p>
        </section>
        <section>
          <h3 className="text-primary">Hébergeur</h3>
          <p>
            La société qui a conçu le site et l’héberge est :
            <br/><br/>REELIANT, 10 rue Auber, 75009 Paris, T. 01 84 25 33 00 - <a href='https://www.reeliant.com/' target='_blank'>https://www.reeliant.com/</a>
            <br/><br/>L'utilisation du présent site, accessible à l’adresse suivante : <a href='https://www.consultation-zonage-de-raccordement-biomethane.fr' target='_blank'>https://www.consultation-zonage-de-raccordement-biomethane.fr</a>, (ci-après désigné le « Site ») est soumise au respect de l'ensemble des conditions énoncées ci-après.
            <br/><br/> Tout Utilisateur doit prendre connaissance des présentes conditions générales d’utilisation (ci-après désignées « CGU ») préalablement à toute utilisation du Site.
            <br/><br/> L’Utilisateur est réputé avoir pris connaissance CGU et les accepter pleinement et entièrement, sans restriction, par la simple utilisation du Site.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 1 – Définitions</h3>
          <p>
            Dans les CGU, les mots ou expressions commençant avec une majuscule ont la signification qui suit :
            <br/>
            <ul>
              <li>Utilisateur : toute personne qui visite une ou plusieurs pages du Site, </li>
              <li>Editeur : GRDF</li>
            </ul>
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 2 – Objet</h3>
          <p>
            Les présentes CGU ont pour objet de définir les règles d’accès et d’utilisation du Site. Elles régissent les relations utilisateur-éditeur à l’exclusion de tout autre convention.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 3 – Informations Techniques</h3>
          <p>
            L'Utilisateur du Site doit disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce Site.
            <br/><br/>Ce Site est optimisé pour tous les terminaux et écrans de résolution 1024 x 768.
            <br/><br/> Afin de garantir un bon fonctionnement du Site, il est conseillé d’utiliser la version la plus récente de votre navigateur.
            <br/><br/> Navigateurs :
            <br/><ul>
              <li>Microsoft Internet Explorer 11 et supérieur</li>
              <li>Mozilla Firefox version 18 et supérieur</li>
              <li>Apple Safari version 5.1 et supérieur</li>
              <li>Google Chrome version 24 et supérieur</li>
            </ul>
            <br/>L’Editeur ne garantit pas la compatibilité du Site avec tout type de matériel ou tout autre configuration.
            <br/><br/>Avant toute utilisation du Site, l'Utilisateur doit vérifier que sa configuration informatique ne contient aucun virus et qu'elle est en parfait état de fonctionnement.
            <br/><br/>Le coût de l'accès et de la navigation sur le Site est à la charge exclusive de l’Utilisateur.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 4 – Accessibilité</h3>
          <p>
            L’Editeur du Site s’engage à faire ses meilleurs efforts afin que le Site soit accessible 24h/24h et 7 jours/7 sauf en cas d’événements constitutifs d’un cas de force majeure et sous réserve des interventions de maintenance. Dans ces deux derniers cas, l’Editeur peut interrompre l’accès au Site à tout moment, sans préavis ni indemnités.
            <br/><br/> L’Editeur ne saurait être tenu pour responsable des dommages directs ou indirects qui pourraient résulter de l'accès ou de l'utilisation du Site, y compris l'inaccessibilité, les pertes de données, détériorations, destructions ou virus qui pourraient affecter l'équipement informatique de l'Utilisateur, et/ou de la présence de virus sur son Site.
            <br/><br/> Avant toute utilisation du Site, l’Utilisateur doit s’être informé des caractéristiques intrinsèques de l'internet et notamment du fait :
            <br/><br/> (i) que la transmission des données sur l’internet ne bénéficie que d'une fiabilité relative, celles-ci circulant sur des réseaux hétérogènes, aux caractéristiques et capacités diverses, qui sont parfois saturés à certaines périodes de la journée et de nature à impacter les délais de téléchargement ou l'accessibilité aux données,
            <br/><br/> (ii) que l'internet est un réseau ouvert et, qu'en conséquence, les informations qu'il véhicule ne sont pas protégées contre les risques de détournement, d'intrusion dans son système, de piratage des données, programmes et fichiers de son système, de contamination par des virus informatiques,
            <br/><br/> (iii) qu'il lui appartient de prendre toutes les mesures appropriées de façon à protéger les données, fichiers ou programmes stockés dans son système contre la contamination par des virus comme des tentatives d'intrusion dans son système.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 5 – Mots de passe</h3>
          <p>
            Le Site permet la création d’espaces sécurisés par la création d’un identifiant et d’un mot de passe.
            L’Utilisateur est seul responsable de cet identifiant et de ce mot de passe et s’engage à ne pas les communiquer à un tiers, pour quelque motif que ce soit.
          </p>
          <p>
            L’Utilisateur prend les précautions nécessaires à leur protection et s’engage à informer immédiatement GRDF de toute utilisation non autorisée de ces données.
          </p>
          <p>
            Le site est un site sécurisé (accès en https aux pages sécurisées nécessitant la connexion de l’Utilisateur).
            L’Utilisateur crée son propre mot de passe dans le respect du jeu de caractères exigé par le site : huit caractères minimum avec au moins une lettre minuscule, une lettre majuscule et un chiffre.
            GRDF préconise d’en changer régulièrement conformément aux procédures décrites pour chacun des espaces ou applications sécurisés concernés.
          </p>
          <p>
            A tout moment, l’Utilisateur peut changer son mot de passe en recourant à la fonctionnalité « mot de passe oublié » ou bien en accédant à la page de gestion de son profil (« Gérer mon profil » > « Modifier mon mot de passe »).
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 6 – Respect de la réglementation applicable</h3>
          <p>
            L’Utilisateur s’engage à utiliser le Site dans le respect de la réglementation applicable en la matière et sans porter préjudice aux intérêts de l'Editeur.
          </p>
          <p>
            Dans l'hypothèse où l'Utilisateur accéderait au Site à partir d'un autre Etat que la France, il s'engage à respecter de la législation et la réglementation localement applicable.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 7 – Informations contenues sur le Site</h3>
          <p>
              Les informations fournies sur le Site le sont à <b>titre informatif</b> et l’Editeur ne saurait garantir l'exactitude, la complétude et l'actualité de ces informations.
          </p>
          <p>
            L’Editeur met tout en œuvre pour offrir aux Utilisateurs des informations et/ou outils disponibles et vérifiés, mais ne saurait être tenu pour responsables des erreurs ou omissions, d'une absence de disponibilité des informations et des services.
          </p>
          <p>
            En conséquence, l'Utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive.
          </p>
          <p>
              L’Utilisateur peut signaler toute erreur à l’Editeur en contactant le webmestre via le formulaire de contact ou à l'adresse suivante : <a href="mailto:contact@grdf.fr"> contact@grdf.fr</a>.
          </p>
          <p>
            En tout état de cause, l’Editeur se réserve également le <b>droit de modifier</b>, à tout moment et sans préavis, les documents, et de manière générale, le contenu du Site, de sorte que les documents diffusés sur ce Site peuvent faire l'objet de mise à jour entre le moment où l’Utilisateur les télécharge et le moment où il les consulte.
          </p>
        </section>
        <section>
          <h3 className="text-primary">
            Article 8 – Modification des Conditions Générales d’Utilisation
          </h3>
          <p>L’Editeur se réserve le droit de revoir périodiquement ces conditions en fonction de l'évolution de son Site.</p>
          <p>
            Les conditions modifiées prendront effet à la date de leur mise à jour en ligne sur le Site.
          </p>
          <p>
            <b>L'Utilisateur s'engage donc à consulter régulièrement les conditions d'utilisation du Site.</b>
          </p>
        </section>
        <section>
          <h3 className="text-primary">
            Article 9 – Propriété intellectuelle
          </h3>
          <p>
            Toutes les informations ou documents contenus dans le Site, les bases de données figurant dans le Site ainsi que tous les éléments créés pour le Site (ci-après le « Contenu ») soit sont la propriété de l’Editeur, soit font l'objet d'un droit d'utilisation, d'exploitation et de reproduction au bénéfice de l’Editeur et sont soumis à la législation protégeant le droit d'auteur.
          </p>
          <p>
            La marque et le logo figurant sur le Site et notamment celle de l’Editeur ou de toute société de son groupe au sens de l'<b>article L 233-3 du code de commerce</b>, sont des marques déposées par les sociétés ou organismes qui en sont propriétaires.
          </p>
          <p>
            Toute exploitation, reproduction, représentation, modification, publication, transmission, dénaturation, totale ou partielle du Site, de son Contenu, des marques ou logos figurant sur le Site, par quelque procédé que ce soit, et sur quelque support que ce soit est interdite et engagerait la responsabilité de l’Utilisateur (articles <b>L335-2</b> et suivants et <b>L 713-2 L713-3 </b>du Code de la Propriété Intellectuelle).
          </p>
          <div>
            L’Editeur accorde à l’Utilisateur un droit d'utilisation, non exclusif et non transférable, du Site et de son Contenu. Le droit ainsi concédé consiste exclusivement en un droit :
            <ul>
              <li>de <b>consultation</b> en ligne du Contenu du Site,</li>
              <li>de <b>reproduction</b> consistant en une impression et/ou une sauvegarde du Contenu. Ce droit d'utilisation s'entend pour un usage strictement privé.</li>
            </ul>
          </div>
          <p>
            Dans l'hypothèse où un utilisateur souhaiterait utiliser un des contenus du site (texte, image...) à d’autres fins, il s'engage à requérir l'autorisation préalable et écrite de GRDF.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 10 – Liens Hypertextes et contenus d’autres sites</h3>
          <h3>10.1    Politique de l’Editeur en matière de liens hypertextes</h3>
          <p>
            Tout lien hypertexte depuis un site tiers vers le Site, quel que soit le type de lien, doit faire l'objet d'une <b>autorisation expresse et préalable de l’Editeur.</b>
          </p>
          <p>
            <b>L’Utilisateur peut adresser sa demande d’autorisation à l’adresse suivante</b>
          </p>
          <p>
            GRDF Direction de la Communication et du Digital
            <br/>6, rue Condorcet
            <br/>75 009 PARIS Cedex 09
            <br/>ou <a href="mailto:contact@grdf.fr">contact@grdf.fr</a>
          </p>
          <p>
            L’Editeur reste libre d’y apporter la réponse de son choix.
          </p>
          <h3>10.2    Liens Hypertextes vers des sites tiers</h3>
          <p>
            Les liens hypertextes mis en place dans le cadre de ce Site en direction d'autres sites Internet ont fait l'objet d'une autorisation préalable et expresse.
          </p>
          <p>
            Toutefois, <b>GRDF n'étant pas l'éditeur de ces sites</b>, il ne peut en contrôler le contenu et les modifications apportées à ce contenu (sauf pour la saisie des coordonnées bancaires du client  afin de recevoir le versement de GRDF par virement, dans ce cas, GRDF utilise le partenaire DOCAPOST et contrôle le contenu affiché). En conséquence l’Editeur ne pourra en aucun cas être tenu pour responsable du contenu des sites ainsi accessibles, ou d'éventuelles collectes et transmission de données personnelles, installation de cookies ou tout autre procédé tendant aux mêmes fins, effectués par ces sites tiers.
          </p>
          <p>
            Si un lien hypertexte amenait l’Utilisateur à pointer vers des sites, pages ou forums dont le titre et/ou les contenus constituent une infraction à la loi ou à la réglementation française, l’Utilisateur devra interrompre sa consultation du site concerné au risque d’encourir les sanctions prévues par la législation française ou de répondre des actions en justice initiées à son encontre.
          </p>
          <p>
            Dans un tel cas, l’Utilisateur doit en informer l’Editeur dans les plus brefs délais à l’adresse suivante :
          </p>
          <p>
            GRDF Direction de la Communication et du Digital
            <br/>6, rue Condorcet
            <br/>75 009 PARIS Cedex 09
            <br/>ou <a href="mailto:contact@grdf.fr">contact@grdf.fr</a>
          </p>
          <p>
            Lorsque le Site peut donner accès à d'autres sites appartenant ou non à l’Editeur, ceux-ci peuvent disposer de leurs propres notices légales que l’Utilisateur devra respecter.
          </p>
        </section>
        <section>
          <h3 className="text-primary">Article 11 – Responsabilité de l’Editeur</h3>
          <p>
            Il est rappelé que l’Editeur n’est responsable que du <b>contenu des pages</b> qu’il édite, dans les limites précisées à l’article 7 des présentes CGU, et ne saurait être tenu pour responsable pour d’autres faits que ceux relatifs à l’édition du Site.
          </p>
          <p>
            De façon générale, l’Editeur n'assure <b>aucune garantie</b>, expresse ou tacite, concernant tout ou partie, du Site.
          </p>
          <p>
            L’Editeur ne peut en aucun cas être tenu responsable de tout dommage susceptible d'intervenir sur le système informatique de l’Utilisateur et/ou de la perte de données résultant de l'utilisation du Site par l’Utilisateur.
          </p>
          <p>
            Conformément aux dispositions de l’article 4 des présentes, il est rappelé que l’Editeur ne garantit pas la disponibilité et/ou les performances du Site et que l’Editeur ne saurait être tenu responsable du non fonctionnement, d'une impossibilité d'accès ou de dysfonctionnements du Site.
          </p>
          <p>
            <b>Les photos sont non contractuelles.</b>
          </p>
        </section>
        <section>
          <h3 className="text-primary">
            Article 12 – Droit applicable et juridictions compétentes
          </h3>
          <p>Le droit applicable est le droit français.</p>
          <p>
            Seule la version en langue française des CGU fait foi entre les parties et pourra être produite en justice.
          </p>
          <p>
            Toute contestation ou litige qui pourrait naître de l'interprétation ou de l'exécution des présentes CGU seront de la compétence exclusive des tribunaux compétent dans le ressort de la Cour d’Appel de Paris (France).
          </p>
        </section>
        <section>
          <h3 className="text-primary">
            Article 13 – Gestion des données collectées
          </h3>
          <h3>13.1    Données relatives à l’utilisateur</h3>
          <p><b>Les données collectées</b> dans le cadre du site sont nécessaires à la gestion des inscriptions des utilisateurs, à l’authentification des utilisateurs sur le site (lors de leurs connexions) et à l’évaluation statistique de la fréquentation du site. Les données collectées dans le cadre du « chat » et des outils d’aide en ligne le sont afin d’améliorer la qualité de la relation client et du service rendu. Les conversations sont enregistrées pour une durée d'un an et ne sont utilisées que dans le cadre de l’amélioration des échanges que vous pourriez avoir avec GRDF. </p>
          <p>
            Les données obligatoires collectées dans le cadre du suivi des versements par GRDF (Nom et IBAN) le sont afin de réaliser le virement de la compensation par virement, sur le compte bancaire de l’Utilisateur. Ces données sont stockées durant 13 mois et ne seront pas utilisées pour une autre finalité.
          </p>
          <h3>13.2    Gestion des cookies</h3>
          <p>
            Pour les besoins et finalités du site, vous n’avez pas la possibilité de refuser les cookies.
          </p>
          <p>
            Lors de la consultation de notre site, des informations relatives à la navigation de votre terminal (ordinateur, tablette, smartphone, etc.) sur notre site, sont susceptibles d'être enregistrées dans des fichiers "Cookies" installés sur votre terminal.
          </p>
          <p>
            Pour les besoins et finalités du site, vous n’avez pas la possibilité de refuser les cookies.
          </p>
          <p>
            A quoi servent les cookies émis sur ce site ? Seul l'émetteur d'un cookie est susceptible de lire ou de modifier des informations qui y sont contenues.
          </p>
          <p>
            <b>Les cookies que nous émettons nous permettent :</b>
          </p>
          <ul>
            <li>d'<b>établir des statistiques</b> et volumes de fréquentation et d'utilisation des diverses éléments composant notre site (rubriques et contenus visités, parcours), nous permettant d'améliorer l'intérêt et l'ergonomie de nos services ;</li>
            <li>d'<b>adapter la présentation de notre site</b> aux préférences d'affichage de votre terminal (langue utilisée, résolution d'affichage, système d'exploitation utilisé, etc) lors de vos visites sur notre Site, selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte;</li>
            <li>de <b>mémoriser des informations relatives</b> à un formulaire que vous avez rempli sur notre site (inscription ou accès à votre compte) ou à des produits, services ou informations que vous avez choisis sur notre site ; de vous permettre d'accéder à des espaces réservés et personnels de notre [site/application], tels que votre compte, grâce à des identifiants ou des données que vous Nous avez éventuellement antérieurement confiés ;</li>
            <li>de <b>mettre en œuvre des mesures de sécurité</b>, par exemple lorsqu'il vous est demandé de vous connecter à nouveau à un contenu ou à un service après une certain laps de temps.</li>
          </ul>
          <h3>13.3    Données statistiques</h3>
          <p>
            GRDF effectue des <b>statistiques sur la fréquentation du site</b> à partir de l’historique des accès au site. Ces statistiques reposent sur la date de visite, l’indication de la page visitée et la typologie du visiteur mais pas ses données personnelles.
          </p>
          <p>
            Ces statistiques ont pour seules finalités l’<b>amélioration des services ou outils proposés</b> et de l’expérience utilisateur. Il s’agit par exemple de connaître les pages les plus visitées ou d’évaluer la fréquentation du site par tranche de temps.
          </p>
          <h3>13.4   Protection des données relatives à l’utilisateur</h3>
          <p>
            Conformément aux dispositions de la <b>loi n°78-17 du 6 janvier 1978 modifiée en 2004</b> relative à l’informatique, aux fichiers et aux libertés, et au Règlement Général pour la Protection des Données.
          </p>
          <p>
            GRDF s’engage à assurer la sécurisation et la <b>confidentialité des données traitées</b> sur les espaces GRDF sécurisés, dans l’état de l’art.
          </p>
          <p>
            Les données à caractère personnel ne sont pas destinées à être communiquées, cédées ou divulguées à des tiers. Toutefois, dans le cadre des missions de GRDF ou de la mise en oeuvre des enquêtes de satisfaction, GRDF pourra être amené à communiquer des données personnelles.
          </p>
          <p>
            Dans le cadre des espaces "collectivités locales", seul le maire d'une commune ou le président d'une structure intercommunale est habilité à recevoir la liste des personnes accédant à l'espace privé de la collectivité locale qu'il représente (ceci tenant au fait qu'il est dépositaire du code de vérification).
          </p>
          <p>
            Pour plus d’information, vous pouvez vous référez <a href="https://www.grdf.fr/cgu-mentions-legales-grdf/politique-confidentialite"> à la charte de confidentialité.</a>
          </p>
          <h3>13.5   Conservation des données relatives à l’utilisateur</h3>
          <p>
            La durée de conservation des données relatives à l’utilisateur correspond à la durée durant laquelle ledit Utilisateur dispose d’un espace GRDF sécurisé et personnalisé. Passé ce délai, les données sont instantanément supprimées du site. Néanmoins, GRDF est amené à les conserver pendant un délai de 10 jours à compter de la suppression de l'espace utilisateur en raison des mesures de sauvegarde et de sécurité prises pour son système d’information.
          </p>
          <h3>13.6   Droit d’accès</h3>
          <p>Le site ainsi que le traitement des données à caractère personnel y afférent ont fait l'objet des formalités définies par la loi.</p>
          <p>En application de la <b>loi du 6 janvier 1978 dite "Informatique et Libertés"</b>, vous bénéficiez d'un droit d'accès, de rectification et de suppression de vos données. Pour exercer ce droit, vous pouvez écrire par courrier GRDF Service Client – Correspondant Informatique et Libertés TSA 85101 27091 EVREUX CEDEX ou <a href="mailto:protectiondesdonnees@grdf.fr">protectiondesdonnees@grdf.fr</a>.</p>
          <h3>13.7   Liste des partenaires</h3>
          <p>Cookie de mesure d’audience et web analyse.</p>
          <p>Ces cookies permettent de mesurer l’audience du site pour en mesurer les performances, détecter les problèmes de navigation, optimiser les performances techniques ou d’ergonomie…</p>
          <p>Google Analytics : <a href="https://policies.google.com/privacy?hl=fr-CA">https://policies.google.com/privacy?hl=fr-CA</a></p>
        </section>
      </CardLayout>
    </>
  );
}

export default injectIntl(LegalMentions);
