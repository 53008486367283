import {
  FilterKeys,
  TypeAvis,
  TypeComment,
  TypeCreateZoneValidation,
  TypeUserMenu
} from "./types";
import {
  ROUTE_PASSWORD,
  ROUTE_PROFILE,
  ROUTE_SPONSORSHIP,
  ROUTE_USER_LIST
} from "./route";

export const STATUT_ZONE = {
  DRAFT: {
    code: "DRAFT",
    description: "En cours de création",
    variant: "primary"
  },
  CONSULTATION: {
    code: "CONSULTATION",
    description: "En cours de consultation",
    variant: "warning"
  },
  CONSULTATION_DONE: {
    code: "CONSULTATION_DONE",
    description: "Consultation terminée",
    variant: "danger"
  },
  DELETED: {
    code: "DELETED",
    description: "Consultation supprimée",
    variant: "danger"
  }
};

export const USER_MENU_LINKS: TypeUserMenu = {
  PROFIL: {
    code: "PROFIL",
    description: "profil_button_label",
    url: ROUTE_PROFILE
  },
  ADMIN: {
    code: "ADMIN",
    description: "admin_button_label",
    url: ROUTE_USER_LIST
  },
  PARRAINAGE: {
    code: "PARRAINAGE",
    description: "parrainage_button_label",
    url: ROUTE_SPONSORSHIP
  }
};

export const LOGO_SRC = "";

export const FILTER = {
  MINE: "mine" as FilterKeys,
  ALL: "all" as FilterKeys,
  DRAFT: "draft" as FilterKeys,
  CONSULTATION: "consultation" as FilterKeys,
  CONSULTATION_DONE: "consultationDone" as FilterKeys,
  DELETED: "deleted" as FilterKeys
};

export const CREATE_ZONE_FIELDS: TypeCreateZoneValidation = {
  name: { required: true, maxLength: 45 },
  reference: { required: true, maxLength: 100 },
  description: { required: false, maxLength: 5000 },
  cantonIds: { required: true },
  region: { required: true },
  depositPotential: { required: true },
  ivValue: { required: true },
  mediumCost: { required: true },
  maxCapacityBeforeEnhancement: { required: true },
  maxCapacityAfterEnhancement: { required: true },
  connectionCost: { required: true },
  enhancementCost: { required: true },
  thirdPartyCost: { required: false },
  projectInService: { required: true },
  projectInjectionSigned: { required: true },
  injectionCapacityInFile: { required: true },
  stakeholders: { required: false, maxLength: 5000 },
  comment: { required: false, maxLength: 5000 },
  image: { required: true },
  consultationEndDate: { required: false }
};

export const VALIDATION_ERRORS = {
  REQUIRED: "Champs requis",
  PDF_FILE_REQUIRED: "Veuillez sélectionner un fichier PDF",
  TOO_LONG: "Trop de caractères",
  FILE_TOO_BIG: "Le fichier est trop volumineux",
  INVALID_FILE_TYPE:
    "Le type de fichier est invalide, veuillez selectionner un fichier : ",
  NOT_EQUAL: "Non identique",
  MAX_LENGTH: "Le champ dépasse la taille limite autorisée"
};

export const COMMENT_TYPE = {
  COMMENT: "COMMENT",
  OPINION: "OPINION"
};

export const UNITS = {
  NM3H: "Nm3/h",
  EURO_BY_NM3H: "€/Nm3/h",
  GWH: "GWh/an",
  M: "M€"
};

export const COSTS = {
  CHEAP: "€",
  MEDIUM: "€€",
  EXPENSIVE: "€€€"
};

export const MEDIUM_COST_OPTIONS = [
  { id: "cheap", label: "€" },
  { id: "medium", label: "€€" },
  { id: "expensive", label: "€€€" }
];

export const COMMENT_MAX_SIZES = {
  name: 100,
  functionName: 100,
  organization: 100,
  opinion: 1000,
  filename: 100
};

export const MAX_FILE_SIZE_MB = 20;

export const INITIAL_COMMENT_STORE = {
  count: 0 as number,
  comments: [] as TypeComment[]
};

export const INITIAL_AVIS_STORE = {
  count: 0 as number,
  avis: [] as TypeAvis[]
};

export const ROLES = {
  ROLE_SUPERADMIN: { role: "ROLE_SUPERADMIN", label: "Super administrateur" },
  ROLE_ADMIN: { role: "ROLE_ADMIN", label: "Administrateur" }
};

export const USER_VALIDATION_MESSAGES = {
  USER_VALIDATED: { type: "success", message: "Utilisateur validé" },
  USER_REFUSED: { type: "success", message: "Utilisateur refusé" },
  INTERNAL_ERROR: { type: "error", message: "Erreur serveur" }
};
