import { createEvent } from "effector";

export const authenticate = createEvent("authenticate");

export const tokenFromSession = createEvent("tokenFromSession");

export const disconnect = createEvent("disconnect");

export const clearZoneDetail = createEvent("clearZoneDetail");

export const clearCommentsAndAvis = createEvent("clearCommentsAndAvis");

export const clearUserDetail = createEvent("clearUserDetail");