import React from "react";
import ReactDOM from "react-dom";
import "bootstrap-css-only/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
// @ts-ignore
import localisation from "moment/locale/fr";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import Messages from "./common/Messages";

ReactDOM.render(
  <IntlProvider locale="fr" messages={Messages}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// Init moment locale to fr
Moment.updateLocale("fr", localisation);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
