import React, { useEffect, useState } from "react";
import { TypeBreadcrumb } from "../../constants/types";
import api from "../../network/api";
import { toast } from "react-toastify";
import { VALIDATION_ERRORS } from "../../constants/constant";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { InjectedIntl, injectIntl } from "react-intl";
import { ROUTE_HOME, ROUTE_PROFILE } from "../../constants/route";
import { navigate } from "@reach/router";

interface Props {
  path: string;
  intl: InjectedIntl;
}

interface TypeForm {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

interface TypeError {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

type FormKeys = keyof TypeForm;

const Password = ({ intl }: Props) => {
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
  const [form, setForm] = useState({
    oldPassword: undefined,
    newPassword: undefined,
    confirmPassword: undefined
  } as TypeForm);
  const [loading, setLoading] = useState(false as boolean);
  const [errors, setErrors] = useState({} as TypeError);

  // Effects
  useEffect(() => {
    const { formatMessage } = intl;
    const newBreadcrumb = [
      {
        id: "fil-ariane-saisie",
        url: ROUTE_PROFILE,
        label: formatMessage({
          id: "breadcrumb_profile_label"
        })
      },
      {
        id: "fil-ariane-saisie",
        label: formatMessage({
          id: "breadcrumb_password_label"
        })
      }
    ];
    setBreadcrumb(newBreadcrumb);
  }, []);

  const handleChange = (event: any) => {
    const { target } = event;
    const newForm = {
      ...form,
      [target.name]: target.value
    };
    setForm(newForm);
    validate(newForm);
  };

  const save = () => {
    if (!loading) {
      const errors = validate(form);
      const errorsKeys = Object.keys(errors) as FormKeys[];
      if (errorsKeys.length === 0) {
        setLoading(true);
        const url = "/api/users/password";
        api
          .putJSON(url, JSON.stringify(form))
          .then(() => {
            toast.success("Le mot de passe a été modifié avec succès.");
            navigate(ROUTE_HOME);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const validate = (form: TypeForm) => {
    const errors = {} as TypeError;
    (Object.keys(form) as FormKeys[]).forEach((key: FormKeys) => {
      if (!form[key]) {
        errors[key] = VALIDATION_ERRORS.REQUIRED;
      }
    });
    if (form.newPassword !== form.confirmPassword) {
      errors.newPassword = VALIDATION_ERRORS.NOT_EQUAL;
      errors.confirmPassword = VALIDATION_ERRORS.NOT_EQUAL;
    }
    setErrors(errors);
    return errors;
  };

  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout idTitle="password_title" bodyClassName="px-4 pb-4">
        <Col
          xs={12}
          sm={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          xl={{ span: 4, offset: 4 }}
        >
          <Form>
            <Row>
              <Col>
                <TextInput
                  idLabel="password_oldPassword"
                  name="oldPassword"
                  disabled={loading}
                  type="password"
                  onChange={handleChange}
                  error={errors.oldPassword}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="password_newPassword"
                  name="newPassword"
                  disabled={loading}
                  type="password"
                  onChange={handleChange}
                  error={errors.newPassword}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  idLabel="password_confirmPassword"
                  name="confirmPassword"
                  disabled={loading}
                  type="password"
                  onChange={handleChange}
                  error={errors.confirmPassword}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-5">
              <ButtonIconBackward
                icon={faSave}
                type="button"
                message="save_button_label"
                className={`mx-2 btn-tertiary ${loading ? "disabled" : ""}`}
                onClick={save}
              />
            </div>
          </Form>
        </Col>
      </CardLayout>
    </>
  );
};

export default injectIntl(Password);
