import React, { useEffect, useState } from "react";
import { TypeBreadcrumb } from "../../constants/types";
import { injectIntl, InjectedIntl } from "react-intl";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import { faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "effector-react";
import { userStore } from "../../state/store";
import { navigate } from "@reach/router";
import { ROUTE_PASSWORD, ROUTE_SPONSORSHIP } from "../../constants/route";
import UnorderedList from "../../components/liste/UnorderedList";

interface Props {
  path: string;
  intl: InjectedIntl;
}

const Profile = ({ intl }: Props) => {
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
  const user = useStore(userStore);

  // Effects
  useEffect(() => {
    const { formatMessage } = intl;
    const newBreadcrumb = [
      {
        id: "fil-ariane-profile",
        label: formatMessage({
          id: "breadcrumb_profile_label"
        })
      }
    ];
    setBreadcrumb(newBreadcrumb);
  }, []);

  const listItems = user
    ? [
        {
          title: "profile_lastname_label",
          content: user.lastname
        },
        {
          title: "profile_firstname_label",
          content: user.firstname
        },
        {
          title: "profile_jobTitle_label",
          content: user.jobTitle
        },
        {
          title: "profile_company_label",
          content: user.company
        },
        {
          title: "profile_email_label",
          content: user.email
        }
      ]
    : [];
  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout
        idTitle="profile_title"
        bodyClassName="px-4 pb-4"
        nextTitleComponent={
          <div className="buttons">
            <ButtonIconBackward
              icon={faLock}
              message="password_button_label"
              className="m-2"
              variant="primary"
              onClick={() => navigate(ROUTE_PASSWORD)}
            />
          </div>
        }
      >
        <UnorderedList className="w-100" items={listItems} />
      </CardLayout>
    </>
  );
};

export default injectIntl(Profile);
