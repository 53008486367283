import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  active?: boolean;
  onClick?: (e: MouseEvent | any) => void;
  icon: IconProp;
  description: string;
  count: number;
  disabled: boolean;
};

const Shortcut = ({
  active,
  onClick,
  icon,
  description,
  count,
  disabled
}: Props) => {
  return (
    <Fragment>
      <div className="shortcut">
        <div
          className={`card shadow text-center h-100 ${active ? "active" : ""} ${
            disabled ? "disabled" : ""
          }`}
          onClick={onClick}
        >
          <div className="card-header d-flex flex-row align-items-center justify-content-center">
            <FontAwesomeIcon icon={icon} />
            <div className="pl-2 pr-2">
              <span className="text-warning">{count}</span>
            </div>
            <div className={`card-close ${active ? "active" : ""}`}>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 30 30"
                fill="#ffffff"
              >
                <path d="M9.4,21.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.5l5.2-5.2L8.9,9.8C8.8,9.7,8.7,9.5,8.8,9.4C8.8,9.2,8.8,9,9,8.9c0.3-0.2,0.7-0.2,0.9,0l5.1,5.2l5.1-5.2c0.2-0.2,0.6-0.3,0.9,0c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.5L15.9,15l5.2,5.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.3,0.2-0.7,0.2-0.9,0L15,15.9l-5.1,5.2C9.7,21.2,9.6,21.3,9.4,21.3z" />
              </svg>
            </div>
          </div>
          <div className="card-body">
            <span>{description}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Shortcut;
