import React, {useEffect, useState} from "react";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import {Col, Form, Row} from "react-bootstrap";
import {InjectedIntl, injectIntl} from "react-intl";
import {TypeBreadcrumb} from "../../constants/types";
import TextInput from "../../components/form/TextInput";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import {faMailBulk, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import api from "../../network/api";
import {toast} from "react-toastify";
import {ROUTE_USER_LIST} from "../../constants/route";
import {navigate} from "@reach/router";
import {useStore} from "effector-react";
import {userDetailStore} from "../../state/store";
import {getUserDetail} from "../../state/effect";
import Loader from "../../components/Loader";
import {VALIDATION_ERRORS} from "../../constants/constant";
import CenteredModal from "../../components/modal/CenteredModal";

type Props = {
    path: string;
    intl: InjectedIntl;
    userId?: number;
};

interface TypeForm {
    idUser?: string | null,
    user : {
        lastname?: string;
        firstname?: string;
        jobTitle?: string;
        company?: string;
        email?: string;
    }
}

interface TypeError {
    lastname?: string;
    firstname?: string;
    jobTitle?: string;
    company?: string;
    email?: string;
}

type FormKeys = keyof TypeForm["user"];

const UserDetail = ({ intl, userId }: Props) => {
    const { formatMessage } = intl;

    // States
    const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
    const [form, setForm] = useState({
        idUser: null,
        user : {
            lastname: undefined,
            firstname: undefined,
            jobTitle: undefined,
            company: undefined,
            email: undefined
        }
    } as TypeForm);
    const [loading, setLoading] = useState(false as boolean);
    const [errors, setErrors] = useState({} as TypeError);
    const [
        showModalConfirmDelete,
        setShowModalConfirmDelete
    ] = useState(false);
    const [
        showModalConfirmResendMail,
        setShowModalConfirmResendMail
    ] = useState(false);

    // Stores
    const userDetail = useStore(userDetailStore);

    // Effects
    useEffect(() => {
        const { formatMessage } = intl;
        const newBreadcrumb = [
            {
                id: "fil-ariane-list",
                label: formatMessage({
                    id: "breadcrumb_user_list_label"
                }),
                url: ROUTE_USER_LIST
            },
            {
                id: "fil-ariane-sponsorship",
                label: formatMessage({
                    id: userId
                        ? "breadcrumb_user_detail_label"
                        : "breadcrumb_sponsorship_label"
                })
            }
        ];
        setBreadcrumb(newBreadcrumb);
    }, []);

    useEffect(() => {
        if (userId) {
            if (userDetail && userDetail.id === userId.toString()) {
                setForm({
                    ...form,
                    idUser: userId.toString(),
                    user: {
                        lastname: userDetail.lastname,
                        firstname: userDetail.firstname,
                        jobTitle: userDetail.jobTitle,
                        company: userDetail.company,
                        email: userDetail.email
                    }
                });
            } else {
                getUserDetail(userId);
            }
        }
    }, [userDetail]);

    const handleChange = (event: any) => {
        const { target } = event;
        const { user } = form;
        const newForm = {
            ...form,
            user: {
                ...user,
                [target.name]: target.value
            }
        }
        setForm(newForm);
        validate(newForm);
    };
    function askDeleteUser() {
        setShowModalConfirmDelete(true);
    }

    function handleDeleteUser() {
        if (!loading) {
            const url = "/api/users";
            api
                .delete(url + "/" + userId)
                .then(() => {
                    toast.success("L'utilisateur a été supprimé avec succès'.");
                    navigate(ROUTE_USER_LIST);
                })
                .finally(() => setLoading(false));
        }
    }

    function askResendMail() {
        setShowModalConfirmResendMail(true);
    }

    function handleResendMail() {
        if (!loading) {
            const url = "/api/users/mail";
            api
                .putResetPasswordAndSendMail(url + "/" + userId)
                .then(() => {
                    toast.success("Le mail a été envoyé avec succès.");
                    navigate(ROUTE_USER_LIST);
                })
                .finally(() => setLoading(false));
        }
    }



    const save = () => {
        if (!loading) {
            const errors = validate(form);
            setErrors(errors);
            const errorsKeys = Object.keys(errors) as FormKeys[];
             if (errorsKeys.length === 0) {
                setLoading(true);
                const url = "/api/users";
                api
                    .putJSON(url + "/" + userId, JSON.stringify(form.user))
                    .then(() => {
                        toast.success("L'utilisateur a été modifié avec succès'.");
                        navigate(ROUTE_USER_LIST);
                    })
                    .finally(() => setLoading(false));
            }
        }
    };

    const validate = (form: TypeForm) => {
        const errors = {} as TypeError;
        const { user } = form;
        (Object.keys(user) as FormKeys[]).forEach((key: FormKeys) => {
            if (!form.user[key]) {
                errors[key] = VALIDATION_ERRORS.REQUIRED;
            }
        });
        return errors;
    };

    const { idUser, user } = form;

    if (userId && !idUser) {
        return (
            <>
                <FilAriane items={breadcrumb} />
                <Loader />
            </>
        );
    }

    return (
        <>
            <FilAriane items={breadcrumb} />
            <CardLayout idTitle="user_detail_title" bodyClassName="px-4 pb-4">
                <Col
                    xs={12}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 3 }}
                    xl={{ span: 4, offset: 4 }}
                >
                    <Form>
                        <Row>
                            <Col>
                                <TextInput
                                    idLabel="sponsorship_lastname"
                                    name="lastname"
                                    disabled={loading}
                                    value={user.lastname}
                                    onChange={handleChange}
                                    error={form.user.lastname ? "" : errors.lastname}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    idLabel="sponsorship_firstname"
                                    name="firstname"
                                    disabled={loading}
                                    value={user.firstname}
                                    onChange={handleChange}
                                    error={form.user.firstname ? "" : errors.firstname}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    idLabel="sponsorship_jobTitle"
                                    name="jobTitle"
                                    disabled={loading}
                                    value={user.jobTitle}
                                    onChange={handleChange}
                                    error={form.user.jobTitle ? "" : errors.jobTitle}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    idLabel="sponsorship_company"
                                    name="company"
                                    disabled={loading}
                                    value={user.company}
                                    onChange={handleChange}
                                    error={form.user.company ? "" : errors.company}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    idLabel="sponsorship_email"
                                    name="email"
                                    disabled={loading}
                                    value={user.email}
                                    onChange={handleChange}
                                    error={form.user.email ? "" : errors.email}
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center mt-5">
                            <ButtonIconBackward
                                icon={faSave}
                                type="button"
                                message="save_button_label"
                                className={`mx-2 btn-tertiary ${loading ? "disabled" : ""}`}
                                onClick={save}
                            />
                            <ButtonIconBackward
                                icon={faTrash}
                                type="button"
                                message="delete_button_label"
                                variant="danger"
                                className={`mx-2 ${loading ? "disabled" : ""}`}
                                onClick={askDeleteUser}
                            />
                            <ButtonIconBackward
                                icon={faMailBulk}
                                type="button"
                                message="resend_mail_button_label"
                                variant="primary"
                                className={`mx-2 ${loading ? "disabled" : ""}`}
                                onClick={askResendMail}
                            />
                        </div>
                    </Form>
                </Col>
            </CardLayout>
            <CenteredModal
                show={showModalConfirmDelete}
                idTitle="delete_user_confirm"
                onConfirm={() => {
                    setShowModalConfirmDelete(false);
                    handleDeleteUser();
                }}
                onCancel={() => {
                    setShowModalConfirmDelete(false);
                }}
            >
                <p>{formatMessage({ id: "definitive_deletion" })}</p>
            </CenteredModal>
            <CenteredModal
                show={showModalConfirmResendMail}
                idTitle="resend_mail_confirm"
                onConfirm={() => {
                    setShowModalConfirmResendMail(false);
                    handleResendMail();
                }}
                onCancel={() => {
                    setShowModalConfirmResendMail(false);
                }}
            >
                <p>{formatMessage({ id: "email_address_reminder" })}{form.user.email}.</p>
                <p>{formatMessage({ id: "correct_email_address" })}</p>
            </CenteredModal>
        </>
    );
};

export default injectIntl(UserDetail);
