import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TypeButtonType } from "../../constants/types";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { TypeButtonVariant } from "../../constants/bootstrapTypes";

type Props = {
  icon?: IconProp;
  onClick?: (e: MouseEvent | any) => void;
  className?: string;
  type?: TypeButtonType;
  message: string;
  disabled?: boolean;
  variant?: TypeButtonVariant;
  hidden?: boolean;
  isLoading?: boolean;
};

const ButtonIconBackward = ({
  icon,
  onClick = () => {},
  className = "",
  type = "submit",
  message = "Soumettre",
  disabled = false,
  variant = "primary",
  hidden = false,
  isLoading = false
}: Props) => (
  <Button
    type={type}
    className={className}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    hidden={hidden}
  >
    <div>
      <span className="icon">
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          icon && <FontAwesomeIcon icon={icon} />
        )}
      </span>
      <span className="ml-2">
        <FormattedMessage id={message} />
      </span>
    </div>
  </Button>
);

export default ButtonIconBackward;
