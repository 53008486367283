import React from "react";
import { FormattedMessage, MessageValue } from "react-intl";
import Form from "react-bootstrap/Form";
import { FormControl, InputGroup } from "react-bootstrap";

type Props = {
  name: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  value: any;
  onChange: (e: any) => void;
  placeholder?: string;
  unit?: string | null;
  min?: number;
  max?: number;
  error?: string;
};

const NumberInput = ({
  name,
  idLabel,
  valuesLabel,
  value,
  onChange,
  placeholder,
  unit = "Nm3/h",
  min,
  max,
  error
}: Props) => (
  <>
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <InputGroup className={!!error ? "mb-5" : "mb-3"}>
        <FormControl
          type="number"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          min={min}
          max={max}
          isInvalid={!!error}
        />
        {unit && (
          <InputGroup.Append>
            <InputGroup.Text id={name}>{unit}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <div className="invalid-feedback d-block mb-5">{error}</div>
      </InputGroup>
    </Form.Group>
  </>
);

export default NumberInput;
