import { hot } from "react-hot-loader/root";
import React, { useEffect } from "react";
import "./App.css";
import LoginPage from "../pages/LoginPage";
import HeaderTopPage from "../pages/HeaderTopPage";
import { navigate, Router } from "@reach/router";
import { authentication, filter, regions } from "../state/store";
import { getRegions, getUser, setFilter } from "../state/effect";
import { TypeRegion } from "../constants/types";
import { tokenFromSession } from "../state/event";
import ZoneRoute from "../views/zone/ZoneRoute";
import LegalMentions from "../views/LegalMentions";
import { ToastContainer } from "react-toastify";
import {
  PATH_ADMIN,
  ROUTE_HOME,
  ROUTE_LEGAL_MENTIONS,
  ROUTE_LOGIN,
  ROUTE_ZONE
} from "../constants/route";
import AdminRoute from "../views/admin/AdminRoute";
import { useStore } from "effector-react";
import { FILTER } from "../constants/constant";
import "../polyfill/promise.prototype.finally";

async function fetchRegions() {
  await getRegions({});
}

function App() {
  const authenticationStore = useStore(authentication);
  const filterStore = useStore(filter);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (!pathname.startsWith(ROUTE_HOME)) {
      navigate(ROUTE_HOME);
    }
  }, []);

  useEffect(() => {
    tokenFromSession();
    fetchRegions();
  }, []);

  useEffect(() => {
    if (authenticationStore.token) {
      getUser(null);
    } else {
      if (!filterStore.all) {
        setFilter(FILTER.ALL);
      }
      if (filterStore.draft) {
        setFilter(FILTER.DRAFT);
      }
      if (filterStore.deleted) {
        setFilter(FILTER.DELETED);
      }
    }
  }, [authenticationStore]);

  return (
    <>
      <Router className="router">
        {authenticationStore && !!authenticationStore.token && (
          <HeaderTopPage path={PATH_ADMIN} component={AdminRoute} />
        )}
        <HeaderTopPage path={ROUTE_ZONE} component={ZoneRoute} />
        <HeaderTopPage path={ROUTE_LOGIN} component={LoginPage} />
        <HeaderTopPage path={ROUTE_LEGAL_MENTIONS} component={LegalMentions} />
      </Router>
      <ToastContainer autoClose={5000} />
    </>
  );
}

export default hot(App);

regions.on(getRegions.done, (state: TypeRegion[], payload) => {
  const result = payload?.result;
  if (result) {
    result.unshift({ id: 0, name: "Toutes" });
    return result;
  }
  return { ...state };
});
