import React from "react";
import { FormattedMessage } from "react-intl";
import Comment from "./Comment";
import { TypeAvis, TypeComment } from "../../constants/types";
import CommentaireForm from "./CommentForm";
import { AvisForm } from "./AvisForm";
import { COMMENT_TYPE, STATUT_ZONE } from "../../constants/constant";

type Props = {
  comments?: TypeComment[];
  avis?: TypeAvis[];
  nbComments?: number;
  nbAvis?: number;
  zoneId: string;
  zoneStatus: string;
  isTokenValid: boolean;
};

const Comments = ({
  comments,
  avis,
  nbComments = comments && comments.length,
  nbAvis = avis && avis.length,
  zoneId,
  zoneStatus,
  isTokenValid
}: Props) => {
  const isConnected = sessionStorage && sessionStorage.token;
  const enableAddForm = ![
    STATUT_ZONE.DRAFT.code,
    STATUT_ZONE.CONSULTATION_DONE.code
  ].includes(zoneStatus);

  return (
    <>
      <div className="commentaire-container bg-grey p-4">
        <h3 className="pb-4 mb-0 text-grey">
          <FormattedMessage id="commentaires_label" />
        </h3>
        <h4 className="pb-4 mb-0 font-primary text-center">
          <FormattedMessage
            id={
              nbComments && nbComments > 1
                ? "nb_commentaires_label_plural"
                : "nb_commentaires_label_singular"
            }
            values={{ nbCommentaires: nbComments }}
          />
        </h4>
        {isConnected && (
          <div className="d-flex flex-column align-items-end pb-4">
            {comments &&
              comments.map(comment => (
                <Comment
                  key={comment.id}
                  commentId={comment.id}
                  name={comment.name}
                  functionName={comment.functionName}
                  organization={comment.organization}
                  opinion={comment.opinion}
                  filename={comment.filename}
                  commentType={COMMENT_TYPE.COMMENT}
                />
              ))}
          </div>
        )}
        {enableAddForm && !isTokenValid && <CommentaireForm zoneId={zoneId} />}
      </div>
      <br />

      <div className="commentaire-container bg-grey p-4">
        <h3 className="pb-4 mb-0 text-grey">
          <FormattedMessage id="avis_label" />
        </h3>
        <h4 className="pb-4 mb-0 font-primary text-center">
          <FormattedMessage id="nb_avis_label" values={{ nbAvis: nbAvis }} />
        </h4>
        {(isConnected || isTokenValid) && (
          <div className="d-flex flex-column align-items-end pb-4">
            {avis &&
              avis.map(itemAvis => (
                <Comment
                  key={itemAvis.id}
                  commentId={itemAvis.id}
                  organization={itemAvis.organization}
                  filename={itemAvis.filename}
                  commentType={COMMENT_TYPE.OPINION}
                />
              ))}
          </div>
        )}
        {enableAddForm && !isConnected && isTokenValid && (
          <AvisForm zoneId={zoneId} />
        )}
      </div>
    </>
  );
};

export default Comments;
