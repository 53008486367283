import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { authentication } from "../state/store";
import { TypeAuthentication } from "../constants/types";
import { login } from "../state/effect";
import { ROUTE_HOME, ROUTE_PASSWORD } from "../constants/route";
import CardLayout from "../components/layouts/CardLayout";
import { InjectedIntl, injectIntl } from "react-intl";
import { useStore } from "effector-react";

interface Props {
  intl: InjectedIntl;
}

const LoginPage = (props: Props) => {
  const authenticationStore = useStore(authentication);

  useEffect(() => {
    if (authenticationStore && authenticationStore.token) {
      navigate(ROUTE_HOME);
    }
  }, [authenticationStore]);

  const submit = async () => {
    const authentication = await login({ username: email, password: password });
    if (authentication && authentication.token !== "") {
      if (authentication.firstConnection) {
        navigate(ROUTE_PASSWORD);
      } else {
        navigate(ROUTE_HOME);
      }
    }
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <CardLayout bodyClassName="px-4 pb-4 details-container">
      <Row>
        <Col md={4} />
        <Col md={4}>
          <Form
            onSubmit={e => {
              e.preventDefault();
              submit();
            }}
          >
            <FormGroup>
              <Label for="loginEmail">Email</Label>
              <Input
                type="email"
                name="email"
                id="loginEmail"
                placeholder=""
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Mot de passe</Label>
              <Input
                type="password"
                name="password"
                id="examplePassword"
                placeholder=""
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </FormGroup>
            <Button type="submit">
              {props.intl.formatMessage({ id: "login_button" })}
            </Button>
          </Form>
        </Col>
        <Col md={4} />
      </Row>
    </CardLayout>
  );
};

export default injectIntl(LoginPage);

authentication.on(login.done, (state: TypeAuthentication, payload: any) => {
  sessionStorage.setItem("token", payload.result.token);
  sessionStorage.setItem("email", payload.result.email);
  return {
    ...state,
    token: payload?.result?.token,
    email: payload?.result?.email
  };
});
