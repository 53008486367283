import React, { ReactNode } from "react";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";
import ButtonIconBackward from "../button/ButtonIconBackward";
import { FormattedMessage } from "react-intl";

interface Props {
  children: ReactNode;
  antiSpamText: string;
  isFirstView: boolean;
  setIsFirstView: (b: boolean) => void;
  bodyMessage?: string;
  isSending: boolean;
}

export function AntiSpam({
  children,
  antiSpamText,
  isFirstView,
  setIsFirstView,
  bodyMessage,
  isSending
}: Props) {
  function toggleView() {
    setIsFirstView(!isFirstView);
  }
  return (
    <div>
      {isFirstView && children}
      {!isFirstView && bodyMessage && (
        <div className="mb-3">
          <FormattedMessage id={isSending ? "sending_message" : bodyMessage} />
        </div>
      )}
      {!isFirstView && (
        <div className="d-flex justify-content-end mt-4">
          <ButtonIconBackward
            icon={faBackspace}
            disabled={isSending}
            onClick={toggleView}
            message={antiSpamText}
          />
        </div>
      )}
    </div>
  );
}
