import React, { useEffect, useState } from "react";
import NumberInput from "./NumberInput";
import { TypeDepositPotential } from "../../constants/types";
import TextInput from "./TextInput";
import ButtonIcon from "../button/ButtonIcon";
import { Col, Form } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

type Props = {
  name: string;
  idLabel: string;
  value: TypeDepositPotential[];
  onChange: (e: any) => void;
  unit: string;
  error?: string;
};

const FormArray = ({ name, idLabel, value, onChange, unit, error }: Props) => {
  const [values, setValues] = useState([...value] as TypeDepositPotential[]);

  useEffect(() => {
    let array = values;
    if (values && values.length === 0 && value && value.length === 0) {
      array = values.concat({
        type: "",
        value: ""
      });
    }
    setValues(array);
  }, [value]);

  const handleChange = (e: any, type: "type" | "value", index: number) => {
    const newValue = e.target.value;
    const depositPotential = {} as TypeDepositPotential;
    depositPotential.type = type === "type" ? newValue : values[index].type;
    depositPotential.value = type === "value" ? newValue : values[index].value;
    values[index] = depositPotential;
    const result = values.filter(val => !!val.type || !!val.value);
    onChange({ target: { name: name, value: result } });
  };

  const handleAdd = () => {
    const array = values.concat({
      type: "",
      value: ""
    });
    setValues(array);
  };

  const handleDelete = (index: number) => {
    const result = values.splice(index, 1);
    onChange({ target: { name: name, value: result } });
  };

  const showError = (
    val: TypeDepositPotential,
    index: number,
    type: "type" | "value"
  ) => {
    return (
      (index === 0 && (!val.type || !val.value)) ||
      (!!val.type && !val.value && type === "value") ||
      (!val.type && !!val.value && type === "type")
    );
  };
  return (
    <>
      {values.map((val, index) => (
        <div key={index} className="d-flex align-items-center">
          <Form.Row className="flex-grow-1">
            <Col sm={12} md={6}>
              <TextInput
                name={name}
                idLabel="create_zone_deposit_potential_type_label"
                value={val.type}
                error={showError(val, index, "type") ? error : ""}
                onChange={(e: Event) => handleChange(e, "type", index)}
              />
            </Col>
            <Col sm={12} md={6}>
              <NumberInput
                name={name}
                idLabel={idLabel}
                value={val.value}
                onChange={(e: Event) => handleChange(e, "value", index)}
                unit={unit}
                error={showError(val, index, "value") ? error : ""}
              />
            </Col>
          </Form.Row>
          {values.length > 1 && (
            <ButtonIcon
              icon={faMinus}
              className="align-self-end mb-3 ml-3"
              onClick={() => handleDelete(index)}
            />
          )}
        </div>
      ))}
      <ButtonIcon icon={faPlus} className="mb-3" onClick={() => handleAdd()} />
    </>
  );
};

export default FormArray;
