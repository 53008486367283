import React, { useEffect, useState } from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import { Col, Form } from "react-bootstrap";
import { faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  CreateZoneKeys,
  KeyCreateZoneErrors,
  TypeBreadcrumb,
  TypeCanton,
  TypeCreateZoneErrors,
  TypeDepositPotential,
  TypeForm,
  TypeOption,
  TypeRegion,
  TypeTooltip,
  TypeZoneDetail
} from "../../constants/types";
import FilAriane from "../../components/breadcrumb/Breadcrumb";
import CardLayout from "../../components/layouts/CardLayout";
import RichText from "../../components/form/RichText";
import NumberInput from "../../components/form/NumberInput";
import FileInput from "../../components/form/FileInput";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import { regions, zoneStore } from "../../state/store";
import { useStore } from "effector-react";
import ButtonIconBackward from "../../components/button/ButtonIconBackward";
import api from "../../network/api";
import { toast } from "react-toastify";
import {
  CREATE_ZONE_FIELDS,
  MEDIUM_COST_OPTIONS,
  STATUT_ZONE,
  UNITS,
  VALIDATION_ERRORS
} from "../../constants/constant";
import AutoCompleteInput from "../../components/form/AutoCompleteInput";
import FormArray from "../../components/form/FormArray";
import { navigate } from "@reach/router";
import { getZoneDetail } from "../../state/effect";
import moment from "moment";
import RadioButtonInput from "../../components/form/RadioButtonInput";
import Loader from "../../components/Loader";
import { ROUTE_HOME } from "../../constants/route";

type Props = {
  intl: InjectedIntl;
  path: string;
  zoneId?: number;
};

function ZoneSaisie({ intl, zoneId }: Props) {
  const { formatMessage } = intl;
  // states
  const [form, setForm] = useState({
    idZone: null,
    zone: {
      name: undefined,
      reference: undefined,
      description: undefined,
      image: undefined,
      cantonIds: [],
      region: undefined,
      depositPotential: [],
      ivValue: undefined,
      mediumCost: undefined,
      maxCapacityBeforeEnhancement: undefined,
      maxCapacityAfterEnhancement: undefined,
      connectionCost: undefined,
      enhancementCost: undefined,
      thirdPartyCost: undefined,
      projectInService: undefined,
      projectInjectionSigned: undefined,
      injectionCapacityInFile: undefined,
      stakeholders: undefined,
      comment: undefined,
      consultationEndDate: undefined
    }
  } as TypeForm);
  const [breadcrumb, setBreadcrumb] = useState([] as TypeBreadcrumb[]);
  const [errors, setErrors] = useState({} as TypeCreateZoneErrors);
  const [loading, setLoading] = useState(false as boolean);
  const [validated, setValidated] = useState(false as boolean);

  // Stores
  const regionList = useStore(regions);
  const zoneDetail = useStore(zoneStore);

  // Effects
  useEffect(() => {
    const newBreadcrumb = [
      {
        id: "fil-ariane-detail",
        url: ROUTE_HOME + "/" + zoneId,
        label: formatMessage({ id: "breadcrumb_details_zone_label" })
      },
      {
        id: "fil-ariane-saisie",
        label: formatMessage({
          id: zoneId
            ? "breadcrumb_edit_zone_label"
            : "breadcrumb_create_zone_label"
        })
      }
    ];
    setBreadcrumb(newBreadcrumb);
  }, []);

  useEffect(() => {
    if (zoneId) {
      if (zoneDetail && zoneDetail.id === zoneId.toString()) {
        const region = regionList.filter(
          region => region.id.toString() === zoneDetail.pcRegionId
        )[0];
        const blob =
          zoneDetail.image && new Blob([new Uint8Array(zoneDetail.image)]);
        const file = blob && new File([blob], "maillage");
        setForm({
          ...form,
          idZone: zoneId.toString(),
          zone: {
            ...zoneDetail,
            image: file,
            region: region && { id: region.id.toString(), label: region.name },
            cantonIds: zoneDetail.pcCantonResources
              ? zoneDetail.pcCantonResources.map(canton => ({
                  id: canton.id.toString(),
                  label: canton.name
                }))
              : [],
            depositPotential: zoneDetail.pcDepositPotentialResources
              ? zoneDetail.pcDepositPotentialResources.map(
                  depositPotential => ({
                    type: depositPotential.label,
                    value: depositPotential.value
                  })
                )
              : [],
            mediumCost: MEDIUM_COST_OPTIONS.filter(
              option =>
                option.id === zoneDetail?.mediumCost?.toLocaleLowerCase()
            )[0],
            consultationEndDate: zoneDetail.consultationEndDate
          }
        });
      } else {
        getZoneDetail(zoneId);
      }
    }
  }, [zoneDetail, regionList]);

  const handleChange = (event: any) => {
    const { target } = event;
    const { zone } = form;
    const newForm = {
      ...form,
      zone: {
        ...zone,
        [target.name]: target.type === "file" ? target.files[0] : target.value
      }
    };
    setForm(newForm);
    validate(newForm);
  };

  const isNotComplete = (
    key: CreateZoneKeys,
    array: TypeDepositPotential[]
  ) => {
    return (
      key === "depositPotential" &&
      Array.isArray(array) &&
      array.map(val => !val.type || !val.value).filter(val => val).length > 0
    );
  };

  const validate = (form: TypeForm): TypeCreateZoneErrors => {
    const errors = {} as TypeCreateZoneErrors;
    const { zone } = form;
    (Object.keys(zone) as CreateZoneKeys[]).forEach((key: CreateZoneKeys) => {
      const array = zone[key] as [];
      const maxLength = CREATE_ZONE_FIELDS[key]?.maxLength;
      const required = CREATE_ZONE_FIELDS[key]?.required;
      if (
        ((!zone[key] && zone[key] !== 0) ||
          array.length === 0 ||
          isNotComplete(key, array)) &&
        required
      ) {
        if ((key === "image" && !zoneId) || key !== "image") {
          errors[key] = VALIDATION_ERRORS.REQUIRED;
        }
      }
      if (maxLength && zone[key] && (zone[key] as string).length > maxLength) {
        errors[key] = VALIDATION_ERRORS.MAX_LENGTH + " : " + maxLength;
      }
    });
    setErrors(errors);
    return errors;
  };

  const callSave = () => {
    const errors = validate(form);
    const errorsKeys = Object.keys(errors) as KeyCreateZoneErrors[];
    if (errorsKeys.length === 0) {
      const url = "/api/zones";
      const { zone } = form;
      const body = {
        ...zone,
        id: form.idZone,
        cantonIds: zone.cantonIds.map((option: TypeOption) => option.id),
        depositPotentialCreationResources: zone.depositPotential.map(
          depositPotential => ({
            label: depositPotential.type,
            value: depositPotential.value
          })
        ),
        name: zone.name,
        description: zone.description,
        pcRegionId: zone.region && zone.region.id,
        mediumCost: zone.mediumCost && zone.mediumCost.id.toUpperCase()
      };
      const call = zoneId
        ? api.putJSON(url + "/" + body.id, JSON.stringify(body))
        : api.postJSON(url, JSON.stringify(body));
      return call.then((detail: TypeZoneDetail) => {
        if (zone.image) {
          const formData = new FormData();
          formData.append("file", zone.image as Blob);
          const url = "/api/zones/" + detail.id + "/image";
          return api.postFormData(url, formData, detail.id).then(() => {
            toast.success(`La zone a été ${zoneId ? "modifiée." : "créée."}`);
            return detail.id;
          });
        } else {
          toast.success(`La zone a été ${zoneId ? "modifiée." : "créée."}`);
          return detail.id;
        }
      });
    } else {
      return Promise.reject("Erreur de validation");
    }
  };

  const save = () => {
    setValidated(true);
    if (!loading) {
      setLoading(true);
      callSave()
        .then((id: string | undefined) => navigate(ROUTE_HOME + "/" + id))
        .finally(() => setLoading(false));
    }
  };

  const launchConsultation = async () => {
    if (!loading) {
      setLoading(true);
      callSave()
        .then((id: string | undefined) => {
          const url = "/api/zones/" + id + "/launch";
          const body = {
            date: form.zone.consultationEndDate
              ? form.zone.consultationEndDate
              : moment(new Date())
                  .add(3, "week")
                  .toDate()
          };
          return api.postJSON(url, JSON.stringify(body)).then(() => {
            toast.success("La zone a été lancée en phase de consultation.");
            return id;
          });
        })
        .then((id: string | undefined) => navigate(ROUTE_HOME + "/" + id))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadOptions = (input: string): Promise<Object[]> =>
    api.getJSON("/api/cantons/" + input).then((cantons: TypeCanton[]) =>
      cantons.map((canton: TypeCanton) => ({
        id: canton.id,
        label: canton.name + " (" + canton.reference + ")"
      }))
    );

  const { idZone, zone } = form;
  if (zoneId && !idZone) {
    return (
      <>
        <FilAriane items={breadcrumb} />
        <Loader />
      </>
    );
  }
  const status = zoneDetail?.status;
  const draftOrNew = !status || status === STATUT_ZONE.DRAFT.code;
  const isDone = status === STATUT_ZONE.CONSULTATION_DONE.code;
  const depositPotentialArray =
    zone.depositPotential &&
    zone.depositPotential.map(potential =>
      parseInt(potential.value ? potential.value : "0")
    );
  const totalDepositPotentials =
    depositPotentialArray &&
    depositPotentialArray.reduce(
      (potential, potential2) => potential + potential2,
      0
    );
  return (
    <>
      <FilAriane items={breadcrumb} />
      <CardLayout
        idTitle={idZone ? "edit_zone_header_title" : "create_zone_header_title"}
        valuesTitle={{ name: zone?.name }}
        bodyClassName="px-4 pb-4"
      >
        <Form>
          {!isDone && <>
            <div className="py-3">
              <h5 className="font-weight-bold">
                <FormattedMessage id="create_zone_informations_zone_label" />
              </h5>
            </div>
            <TextInput
              name="name"
              idLabel="create_zone_nom_label"
              value={zone.name}
              error={validated ? errors.name : ""}
              onChange={handleChange}
            />
            <TextInput
              name="reference"
              idLabel="create_zone_reference_label"
              value={zone.reference}
              error={validated ? errors.reference : ""}
              onChange={handleChange}
            />
            <RichText
              name="description"
              idLabel="create_zone_description_label"
              value={zone.description}
              error={validated ? errors.description : ""}
              onChange={handleChange}
            />
            <FileInput
              name="image"
              idLabel="create_zone_maillages_label"
              buttonLabel={zoneId ? "edit_file_button_label" : undefined}
              accept="image/png, image/jpeg"
              file={zone.image}
              onChange={handleChange}
              error={validated ? errors.image : ""}
            />
            <Form.Row>
              <Col sm={12} md={6}>
                <AutoCompleteInput
                  name="cantonIds"
                  idLabel="create_zone_cantons_label"
                  onChange={handleChange}
                  loadOptions={loadOptions}
                  error={validated ? errors.cantonIds : ""}
                  options={zone.cantonIds}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectInput
                  name="region"
                  idLabel="create_zone_region_label"
                  placeholder="Sélectionner une région"
                  value={zone.region}
                  onChange={handleChange}
                  enumOptions={regionList
                    .filter((region: TypeRegion) => region.id !== 0)
                    .map((region: TypeRegion) => ({
                      id: region.id.toString(),
                      label: region.name
                    }))}
                  error={validated ? errors.region : ""}
                />
              </Col>
            </Form.Row>
          </>
          }
          {!isDone && <>
            <div className="py-3 mt-4">
              <h5 className="font-weight-bold">
                <FormattedMessage id="create_zone_deposit_potential_label" />
              </h5>
            </div>
            <Form.Row>
              <Col sm={12} md={12}>
                <FormArray
                  name="depositPotential"
                  idLabel="create_zone_deposit_potential_value_label"
                  value={zone.depositPotential}
                  onChange={handleChange}
                  unit={UNITS.GWH}
                  error={validated ? errors.depositPotential : ""}
                />
                {totalDepositPotentials && totalDepositPotentials !== 0 ? (
                  <div>
                    <FormattedMessage id={"total_deposit_potential"} /> :{" "}
                    {totalDepositPotentials.toString()} {UNITS.GWH}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Form.Row>
            <div className="py-3 mt-4">
              <h5 className="font-weight-bold">
                <FormattedMessage id="create_zone_core_label" />
              </h5>
            </div>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="ivValue"
                  idLabel="create_zone_iv_value_label"
                  value={zone.ivValue}
                  onChange={handleChange}
                  error={validated ? errors.ivValue : ""}
                  unit={UNITS.EURO_BY_NM3H}
                />
              </Col>
              <Col sm={12} md={6}>
                <RadioButtonInput
                  name="mediumCost"
                  idLabel="create_zone_medium_cost_label"
                  onChange={handleChange}
                  enumOptions={MEDIUM_COST_OPTIONS}
                  value={zone.mediumCost}
                  error={validated ? errors.mediumCost : ""}
                  tooltip={
                    {
                      placement: "top",
                      tooltipTitle: formatMessage({
                        id: "tooltip_zone_medium_cost_title"
                      }),
                      tooltipContent: (
                        <div>
                          <div>
                            <FormattedMessage
                              id={"tooltip_zone_medium_cost_text_1"}
                            />
                          </div>
                          <div>
                            <FormattedMessage
                              id={"tooltip_zone_medium_cost_text_2"}
                            />
                          </div>
                          <div>
                            <FormattedMessage
                              id={"tooltip_zone_medium_cost_text_3"}
                            />
                          </div>
                        </div>
                      )
                    } as TypeTooltip
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="maxCapacityBeforeEnhancement"
                  idLabel="create_zone_max_capacity_before_enhancement_label"
                  value={zone.maxCapacityBeforeEnhancement}
                  onChange={handleChange}
                  error={validated ? errors.maxCapacityBeforeEnhancement : ""}
                />
              </Col>
              <Col sm={12} md={6}>
                <NumberInput
                  name="maxCapacityAfterEnhancement"
                  idLabel="create_zone_max_capacity_after_enhancement_label"
                  value={zone.maxCapacityAfterEnhancement}
                  onChange={handleChange}
                  error={validated ? errors.maxCapacityAfterEnhancement : ""}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="connectionCost"
                  idLabel="create_zone_connection_cost_label"
                  value={zone.connectionCost}
                  onChange={handleChange}
                  unit="M€"
                  error={validated ? errors.connectionCost : ""}
                />
              </Col>
              <Col sm={12} md={6}>
                <NumberInput
                  name="enhancementCost"
                  idLabel="create_zone_enhancement_cost_label"
                  value={zone.enhancementCost}
                  onChange={handleChange}
                  placeholder=""
                  unit="M€"
                  error={validated ? errors.enhancementCost : ""}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="thirdPartyCost"
                  idLabel="create_zone_third_party_cost_label"
                  value={zone.thirdPartyCost}
                  onChange={handleChange}
                  placeholder=""
                  unit="M€"
                  error={validated ? errors.thirdPartyCost : ""}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="projectInService"
                  idLabel="create_zone_project_in_service_label"
                  value={zone.projectInService}
                  onChange={handleChange}
                  unit={null}
                  error={validated ? errors.projectInService : ""}
                />
              </Col>
              <Col sm={12} md={6}>
                <NumberInput
                  name="projectInjectionSigned"
                  idLabel="create_zone_project_injection_signed_label"
                  value={zone.projectInjectionSigned}
                  onChange={handleChange}
                  unit={null}
                  error={validated ? errors.projectInjectionSigned : ""}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={12} md={6}>
                <NumberInput
                  name="injectionCapacityInFile"
                  idLabel="create_zone_injection_capacity_in_file_label"
                  value={zone.injectionCapacityInFile}
                  onChange={handleChange}
                  error={validated ? errors.injectionCapacityInFile : ""}
                />
              </Col>
            </Form.Row>
          </>
          }
          <div className={"py-3" + (isDone ? null : "mt-4")}>
            <h5 className="font-weight-bold">
              <FormattedMessage id="create_zone_informations_consultation_label" />
            </h5>
          </div>
          {!isDone &&
            <TextInput
              name="stakeholders"
              idLabel="create_zone_stakeholders_label"
              value={zone.stakeholders}
              onChange={handleChange}
              error={validated ? errors.stakeholders : ""}
            />
          }
          <RichText
            name="comment"
            idLabel="create_zone_comment_label"
            value={zone.comment}
            error={validated ? errors.comment : ""}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-center mt-5">
            <ButtonIconBackward
              icon={faSave}
              type="button"
              message="save_button_label"
              className={`mx-2 btn-tertiary ${loading ? "disabled" : ""}`}
              onClick={save}
            />
            {draftOrNew && (
              <ButtonIconBackward
                icon={faPlusCircle}
                type="button"
                message="create_zone_consultation_btn_label"
                variant="primary"
                className={`mx-2 ${loading ? "disabled" : ""}`}
                onClick={launchConsultation}
              />
            )}
          </div>
        </Form>
      </CardLayout>
    </>
  );
}

export default injectIntl(ZoneSaisie);
