import React from "react";
import { ActionMeta, OptionsType, Theme, ValueType } from "react-select";
import AsyncSelect from "react-select/async";
import Form from "react-bootstrap/Form";
import {
  FormattedMessage,
  InjectedIntl,
  injectIntl,
  MessageValue
} from "react-intl";
import { TypeOption } from "../../constants/types";

type Props = {
  name?: string;
  idLabel: string;
  valuesLabel?: { [key: string]: MessageValue | JSX.Element };
  onChange: (e: any) => void;
  placeholder?: string;
  loadOptions: (input: string) => Promise<Object[]>;
  intl: InjectedIntl;
  error?: string;
  options: TypeOption[];
};

let timer: NodeJS.Timeout;
let lastInput = "";

function AutoCompleteInput({
  name,
  idLabel,
  valuesLabel,
  onChange,
  placeholder = "Sélectionner des cantons",
  loadOptions,
  intl,
  error,
  options
}: Props) {
  const asyncLoadOptions = (input: string) => {
    return new Promise(resolve => {
      clearTimeout(timer);
      timer = setTimeout(() => resolve(loadOptions(input)), 500);
    });
  };

  const { formatMessage } = intl;

  return (
    <Form.Group controlId={name}>
      <Form.Label column={name as any} className="overflow-ellipsis">
        <span className="text-grey">
          <FormattedMessage id={idLabel} values={valuesLabel} />
        </span>
      </Form.Label>
      <AsyncSelect
        noOptionsMessage={() => formatMessage({ id:  lastInput == "" ? "empty_search" : "no_result" }) || null}
        loadingMessage={() => formatMessage({ id: "loading" }) || null}
        placeholder={placeholder}
        name={name}
        onChange={(e: ValueType<TypeOption>, action: ActionMeta) => {
          if (action.action === "select-option") {
            onChange({
              target: { name: name, value: e }
            });
          } else if (action.action === "remove-value") {
            const list = options.filter(
              option => option.id !== (action as any).removedValue.id
            );
            onChange({ target: { name: name, value: list } });
          }
        }}
        onInputChange={val => {lastInput = val}}
        value={options}
        closeMenuOnSelect={false}
        isMulti
        loadOptions={asyncLoadOptions}
        isOptionSelected={(
          option: TypeOption,
          list: OptionsType<TypeOption>
        ): boolean =>
          !!list.find(item => item && option && item.id === option.id)
        }
        theme={
          {
            spacing: {
              baseUnit: 5,
              controlHeight: 44,
              menuGutter: 10
            }
          } as Theme
        }
        className={!!error ? "invalid select" : ""}
      />
      <div className="invalid-feedback d-block">{error}</div>
    </Form.Group>
  );
}

export default injectIntl(AutoCompleteInput);
