import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { USER_MENU_LINKS } from "../constants/constant";
import { FormattedMessage } from "react-intl";
import { TypeAuthentication, UserMenuKeys } from "../constants/types";
import { navigate } from "@reach/router";
import { useStore } from "effector-react";
import { authentication, userStore } from "../state/store";
import { disconnect } from "../state/event";
import api from "../network/api";
import { ROUTE_HOME } from "../constants/route";

function UserMenu() {
  const user = useStore(userStore);

  const userMenuLinks = Object.keys(USER_MENU_LINKS);

  const disconnectFunction = () => {
    disconnect();
    api.getJSON("/logout");
    navigate(ROUTE_HOME);
  };

  return (
    <Dropdown className="user-menu" alignRight>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        className="user-menu-btn d-flex justify-content-between align-items-center"
      >
        <div className="user-icon d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faUserCircle} />
        </div>
        {user && (
          <div className="user-name text-center mx-2">
            {user.firstname + " " + user.lastname}
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {(userMenuLinks as UserMenuKeys[]).map((link: UserMenuKeys) => (
          <Dropdown.Item key={link} href={USER_MENU_LINKS[link].url}>
            <FormattedMessage id={USER_MENU_LINKS[link].description} />
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={disconnectFunction}>
          <FormattedMessage id="deconnexion_button_label" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserMenu;

authentication.on(disconnect, (state: TypeAuthentication) => {
  sessionStorage.clear();
  return { ...state, token: "", email: "" };
});
