import React, { RefObject, useEffect, useState } from "react";
import "react-table-6/react-table.css";
import { injectIntl } from "react-intl";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";
import ReactTable, { Column, RowInfo, SortingRule } from "react-table-6";
import { getLienById } from "../../utils/LienUtils";
import ButtonIconForward from "../button/ButtonIconForward";
import ButtonIconBackward from "../button/ButtonIconBackward";
import { navigate } from "@reach/router";
import { TypeProperty } from "../../constants/types";
import { PATH_EDIT, ROUTE_HOME } from "../../constants/route";

type Props<T extends Object> = {
  datas: T[];
  sortingAttributes: string[];
  columns: Column[];
  intl: {
    formatMessage: Function;
  };
  filters?: {
    label: string;
    field: string;
    values: {
      label: string;
      value: string;
    }[];
  }[];
  properties: TypeProperty;
  from?: Date;
  to?: Date;
  showPaginationBottom?: boolean;
  clickableLines?: boolean;
  initialSortingRules?: SortingRule[];
  className?: string;
  loading: boolean;
  sendPage?: (n: number) => void;
  sendPageSize?: (n: number) => void;
  resizable?: boolean;
};

function Liste<T extends Object>({
  initialSortingRules = [
    {
      id: "modificationDate",
      desc: true
    }
  ],
  datas,
  sortingAttributes,
  columns,
  intl,
  properties = {
    idListe: "liste",
    lienPrefix: "liste",
    queryFilterLabel: null,
    showTable: true
  },
  showPaginationBottom = true,
  clickableLines = true,
  className = "liste-results -striped",
  loading = false,
  sendPage = (n: number) => null,
  sendPageSize = (n: number) => null,
  resizable = true
}: Props<T>) {
  const table: RefObject<ReactTable<Object>> = React.createRef();
  const { formatMessage } = intl;
  const rowClickable = getLienById(`${properties.lienPrefix}-details`);

  const [page, setPage] = useState(1 as number);
  const [pages, setPages] = useState(1 as number);
  const [pageSize, setPageSize] = useState(10 as number);
  const [sort, setSort] = useState(initialSortingRules);

  useEffect(() => {
    updatePage();
  }, [datas.length, page, pageSize]);

  const translations = {
    previousText: formatMessage({ id: "table_previous_text" }),
    nextText: formatMessage({ id: "table_next_text" }),
    loadingText: formatMessage({ id: "table_loading_text" }),
    noDataText: formatMessage({ id: "table_no_data_text" }),
    pageText: formatMessage({ id: "table_page_text" }),
    ofText: formatMessage({ id: "table_of_text" }),
    rowsText: formatMessage({ id: "table_rows_text" })
  };

  const getStart = (): number => {
    return (page - 1) * pageSize;
  };

  const getEnd = (): number => {
    return page * pageSize;
  };

  const updatePage = () => {
    const maxPage = Math.ceil(datas.length / pageSize!);
    setPages(maxPage);
    if (page > maxPage && maxPage) {
      setPage(maxPage);
      sendPage(maxPage);
    }
  };

  const onPageChange = (value: number) => {
    setPage(value + 1);
    sendPage(value + 1);
  };

  const onPageSizeChange = (value: number) => {
    setPageSize(value);
    sendPageSize(value);
  };

  const onSortChange = (newSorted: SortingRule[], column: any) => {
    const currentSort = sort[0];
    setSort([
      {
        id: column.id,
        desc: currentSort.id === column.id ? !currentSort.desc : true
      }
    ]);
  };

  const sortMethod = (a: T, b: T) => {
    const sortRule = sort[0];
    if (sortingAttributes.includes(sortRule.id)) {
      if (typeof a === "object" && typeof b === "object") {
        return sortAttribute(
          (a as any)[sortRule.id],
          (b as any)[sortRule.id],
          sortRule.desc
        );
      }
    }
    return 0;
  };

  const sortAttribute = (
    a: string | Date | null,
    b: string | Date | null,
    isDesc: boolean
  ): 1 | 0 | -1 => {
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    if (typeof a === "string" && typeof b === "string") {
      a = (a as string).toLowerCase();
      b = (b as string).toLowerCase();
    }
    if (a < b) {
      return isDesc ? 1 : -1;
    } else {
      return isDesc ? -1 : 1;
    }
  };

  return (
    <div className="liste">
      <ReactTable
        className={`${className} ${clickableLines ? "" : "no-click"}`}
        {...translations}
        page={page - 1}
        pages={pages}
        pageSize={pageSize}
        ref={table}
        data={datas.sort(sortMethod).slice(getStart(), getEnd())}
        defaultPageSize={10}
        manual
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        defaultSorted={initialSortingRules}
        onSortedChange={onSortChange}
        resizable={resizable}
        loading={loading}
        columns={columns}
        minRows={-1}
        showPaginationBottom={showPaginationBottom}
        PreviousComponent={(props: Props<T>, rest: Props<T>) => (
          <ButtonIconBackward
            {...rest}
            {...props}
            message="table_previous_text"
            icon={faAngleDoubleLeft}
            variant="link"
          />
        )}
        NextComponent={(props: Props<T>) => (
          <ButtonIconForward
            {...props}
            message="table_next_text"
            icon={faAngleDoubleRight}
            variant="link"
          />
        )}
        getTdProps={(
          state: any,
          rowInfo?: RowInfo,
          column?: Column
        ): object | undefined => ({
          onClick: (e: Event, handleOriginal: Function) => {
            if (handleOriginal) {
              if (
                rowInfo &&
                rowClickable &&
                column &&
                column.className !== "actions"
              ) {
                // On retire le /{id} de l'url
                const baseUrl = rowClickable.url.slice(0, -5);
                if (properties.lienCallback) {
                  properties.lienCallback(rowInfo.original);
                } else if (rowInfo.original.statut === "EN_COURS_CREATION") {
                  navigate(`${ROUTE_HOME}/${rowInfo.original.id}${PATH_EDIT}`);
                } else {
                  navigate(`${baseUrl}/${rowInfo.original.id}`);
                }
              } else {
                handleOriginal();
              }
            }
          }
        })}
      />
    </div>
  );
}

export default injectIntl(Liste);
